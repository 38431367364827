import { useManagedProp } from '@common/use';
import type { iconMap } from '@common/pf4-compat/Pf3Icon.vue';
import { DateTime } from 'luxon';
import { type ComputedRef, inject, type InjectionKey, type Ref, ref, unref, getCurrentInstance, onBeforeUpdate, computed, reactive } from 'vue';
import { useInputValidity } from '../input';
import ip from 'ip-regex';
import { parseDateTime } from '../date';
import { watch } from 'vue';

export const FormGroupDisabledInjectionKey = Symbol('FormGroupDisabledInjectionKey') as InjectionKey<boolean | ComputedRef<boolean>>;

interface InputTypeValidator {
  title: string;
  test?: (value: string | number | unknown) => boolean | string;
  pattern?: string;
}

const defineCustomTypes = ($t: (s: string, named?: object) => string) => ({
  ip: {
    title: 'Indirizzo IP non valido',
    test: (v: string | number | unknown) => typeof v === 'string' && ip({ exact: true }).test(v),
  } as InputTypeValidator,

  ipv4: {
    title: 'Indirizzo IPv4 non valido',
    test: (v: string | number | unknown) => typeof v === 'string' && ip.v4({ exact: true }).test(v),
  } as InputTypeValidator,

  ipv6: {
    title: 'Indirizzo IPv6 non valido',
    test: (v: string | number | unknown) => typeof v === 'string' && ip.v6({ exact: true }).test(v),
  } as InputTypeValidator,

  identifier: {
    title: $t(
      'Non è un identificatore valido. Sono permesse solo lettere (a-z), numeri (0-9) e il trattino basso (underscore). I numeri non sono permessi come primo carattere.',
    ),
    pattern: '^[a-z_]+[a-z0-9_]*$',
  } as InputTypeValidator,

  domain: {
    title: $t('Non è un nome di dominio internet valido'),
    pattern:
      '^([a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]([\\-a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]*[a-zàâäèéêëìîïòôöùûüæœçÿβ0-9])?\\.)+[a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]+$',
  } as InputTypeValidator,

  dkim: {
    title: $t('Formato DKIM non valido'),
    test: (value: string | number | unknown) => {
      if (typeof value !== 'string') {
        return false;
      }
      const parts = value.split(';').filter(Boolean);
      const data: Record<string, string> = {};
      for (const part of parts) {
        const kv = part.trim().split('=');
        if (kv.length != 2) {
          return false;
        }
        data[kv[0]] = kv[1];
      }
      if (data.v && data.v != 'DKIM1') {
        return $t("Formato DKIM non valido: v=DKIM1 è l'unico valore permesso");
      }
      if (data.k && data.k != 'rsa') {
        return $t("Formato DKIM non valido: k=rsa è l'unico valore permesso");
      }
      if (!data.p) {
        return $t('Formato DKIM non valido: "p=" mancante');
      }
      try {
        atob(data.p);
      } catch (e) {
        return $t('Formato DKIM non valido: "p=" non è in codifica base64');
      }
      return true;
    },
  } as InputTypeValidator,

  dmarc: {
    title: $t('Formato DMARC non valido'),
    test: (value: string | number | unknown) => {
      if (typeof value !== 'string') {
        return false;
      }
      const parts = value.split(';').map(s => s.trim()).filter(Boolean);
      const data: Record<string, string> = {};
      for (const part of parts) {
        const kv = part.trim().split('=');
        if (kv.length != 2) {
          return false;
        }
        data[kv[0]] = kv[1];
      }
      if (data.v && data.v != 'DMARC1') {
        return $t("Formato DMARC non valido: v=DMARC1 è l'unico valore permesso");
      }
      if (!data.p) {
        return $t('Formato DMARC non valido: "p=" mancante');
      }
      if (data.pct) {
        const pct = Number(data.pct);
        if (isNaN(pct)) {
          return $t("Formato DMARC non valido: pct deve essere un numero");
        }
        if (pct < 1 || pct > 100) {
          return $t("Formato DMARC non valido: pct deve essere un numero compreso tra 1 e 100");
        }
      }
      if (data.fo && !['0', '1', 'd', 's'].includes(data.fo)) {
        return $t(`Formato DMARC non valido: il campo fo può contenere solo "0", "1", "d" o "s"`);
      }
      for (const field of ['rua', 'ruf']) {
        if (data[field]) {
          const emails = data[field].split(',');
          for (const email of emails) {
            if (!email.startsWith('mailto:')) {
              return $t(`Formato DMARC non valido: le email in {field} devono cominciare con mailto:`, { field });
            }
          }
        }
      }
      for (const field of ['p', 'sp']) {
        if (data[field] && !['none', 'quarantine', 'reject'].includes(data[field])) {
          return $t(`Formato DMARC non valido: il campo {field} può contenere solo "none", "quarantine" o "reject"`, { field });
        }
      }
      for (const field of ['adkim', 'aspf']) {
        if (data[field] && !['s', 'r'].includes(data[field])) {
          return $t(`Formato DMARC non valido: il campo {field} può contenere solo "s" o "r"`, { field });
        }
      }
      return true;
    },
  } as InputTypeValidator,
});

export interface Props {
  modelValue?: string | number | Date | DateTime | null;
  prepend?: string;
  append?: string;
  icon?: keyof typeof iconMap;
  disabled?: boolean;
  withCheckbox?: string | boolean;
  checked?: boolean;
  resetValue?: string | number;
  type?: keyof ReturnType<typeof defineCustomTypes> |
    'text' | 'number' | 'email' | 'tel' | 'hidden' | 'color'| 'date' |
    'time' | 'datetime-local' | 'file' | 'month' | 'password' |
    'range' | 'search' | 'url' | 'new-password' | 'datetime';
  pattern?: string;
  title?: string;
  customValidity?: string;
  step?: string | number;
  autoRound?: boolean;
  class?: any;
}

export interface Emits {
  (name: 'update:checked', value: boolean): void;
  (name: 'update:modelValue', value: string | number | Date | DateTime): void;
}

export function useXFormElement(props: Readonly<Props>, emit: Emits, $t: (s: string, named?: object) => string = s => s) {
  const input: Ref<HTMLInputElement | HTMLSelectElement | null> = ref(null);
  const value = useManagedProp('modelValue', '');
  const instance = getCurrentInstance()?.proxy;
  const internalChecked = ref(false);
  const withSlot: Record<string, boolean> = reactive({});
  const customTypes = defineCustomTypes($t);

  const groupDisabled = inject(FormGroupDisabledInjectionKey, false);

  const hasPreAddon = computed(() => {
    return props.prepend || withSlot.prepend || props.withCheckbox;
  });

  const hasPostAddon = computed(() => {
    return props.append || props.icon || withSlot.append;
  });

  const effectiveDisabled = computed(() => {
    return props.disabled || unref(groupDisabled) || (Boolean(props.withCheckbox) && !effectiveChecked.value);
  });

  const effectiveChecked = computed({
    get: () => props.checked ?? internalChecked.value ?? false,
    set(value: boolean) {
      value = Boolean(value);
      internalChecked.value = value;
      emit('update:checked', value);
    },
  });

  const sourceDate = computed(() => {
    if (inputType.value === 'datetime-local' && value.value) {
      return parseDateTime(value.value);
    }
    return null;
  });

  const inputType = computed(() => {
    if (props.type === 'new-password') {
      return 'password';
    }
    if (props.type === 'datetime') {
      return 'datetime-local';
    }
    if (
      props.type &&
      [
        'text',
        'number',
        'email',
        'tel',
        'hidden',
        'color',
        'date',
        'time',
        'datetime-local',
        'file',
        'month',
        'password',
        'range',
        'search',
        'url',
      ].includes(props.type)
    ) {
      return props.type;
    }
    return 'text';
  });

  const inputValue = computed(() => {
    if (inputType.value === 'datetime-local' && sourceDate.value !== null) {
      const value = sourceDate.value;
      // Remove timezone and seconds/milliseconds from string
      return value.toISO()?.replace(/T([0-9]{2}:[0-9]{2})(?::[0-9]{2})?(?:\.[0-9]{3})?(?:Z|[+-][0-9:]+)$/, 'T$1');
    }

    if (inputType.value === 'number' && props.autoRound) {
      const numValue = Number(value.value);

      if (isNaN(numValue)) {
        return value.value;
      }

      let precision = 0;
      if (props.step) {
        const step = String(props.step);
        const dotPos = step.lastIndexOf('.');
        precision = step.substring(dotPos < 0 ? step.length : dotPos + 1).length;
      }

      precision = Math.pow(10, precision);
      return Math.round((numValue + Number.EPSILON) * precision) / precision;
    }

    return value.value;
  });

  const typePattern = computed(() => {
    const type = props.type ?? 'text';
    if (type in customTypes) {
      return customTypes[type as keyof typeof customTypes].pattern;
    }
    return undefined;
  });

  const typeTitle = computed(() => {
    const type = props.type ?? 'text';
    if (type in customTypes) {
      return customTypes[type as keyof typeof customTypes].title;
    }
    return undefined;
  });

  onBeforeUpdate(() => {
    withSlot.prepend = Boolean(instance?.$slots.prepend);
    withSlot.append = Boolean(instance?.$slots.append);
  });

  function update(v: unknown) {
    // this.isInvalid = false;
    value.value = v as any;
  }

  function reset() {
    if (typeof props.resetValue !== 'undefined' && value.value !== props.resetValue) {
      update(props.resetValue);
    }
  }

  function doValidate() {
    if (effectiveDisabled.value || !value.value) {
      setCustomValidity(effectiveDisabled.value ? '' : props.customValidity ??'');
      return;
    }

    let testFn: ((value: unknown) => boolean | string) = () => '';
    const type = props.type ?? 'text';
    if (type in customTypes) {
      const customTypeTest = customTypes[type as keyof typeof customTypes].test;
      if (customTypeTest) {
        testFn = value => customTypeTest(value);
      }
    }

    const result = testFn(value.value);
    if (typeof result === 'string') {
      setCustomValidity(result || (props.customValidity ?? ''));
    } else if (result === false) {
      setCustomValidity(typeTitle.value ?? $t('Formato non valido'));
    } else {
      setCustomValidity(props.customValidity ?? '');
    }
  }

  function change(e: Event) {
    if (!(e.target instanceof HTMLInputElement)) {
      return;
    }

    if (inputType.value === 'datetime-local') {
      const date = parseDateTime(e.target.value);

      if ((value.value as any) instanceof DateTime) {
        update(date);
        return;
      }

      if (typeof value.value === 'string') {
        update(date.toISO());
        return;
      }

      if (typeof value.value === 'number') {
        update(date.toMillis() / (value.value > 10000000000 ? 1 : 1000));
        return;
      }
    }

    update(e.target.value);
  }

  const { refreshValidity, setCustomValidity, ...inputValidityRest } = useInputValidity(input, doValidate);

  watch([effectiveDisabled, () => [props.type, props.customValidity]], refreshValidity);

  return {
    change,
    doValidate,
    effectiveChecked,
    effectiveDisabled,
    groupDisabled,
    hasPostAddon,
    hasPreAddon,
    input,
    inputType,
    inputValue,
    refreshValidity,
    reset,
    typePattern,
    typeTitle,
    update,
    value,
    ...inputValidityRest,
  };
}
