<template>
  <a @click="sortby">
    <slot />
    {{ ' ' }}
    <span v-if="modelValue == field && !reverse">▾</span>
    <span v-if="modelValue == field && reverse">▴</span>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: 'SortLink',

  props: {
    modelValue: String,
    field: String,
    reverse: Boolean,
  },

  emits: ['update:modelValue', 'update:reverse'],

  methods: {
    sortby() {
      if (this.modelValue == this.field) {
        this.$emit('update:reverse', !this.reverse);
      } else {
        this.$emit('update:modelValue', this.field);
        if (this.reverse) {
          this.$emit('update:reverse', false);
        }
      }
    },
  },
});
</script>
