import { nextTick, onBeforeUnmount, onMounted, ref, type Ref, computed } from "vue";
import type PfFormSelect from '@common/pf4-compat/PfFormSelect.vue';

type NativeValidityFormElement = HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement;
type ValidityFormElement = NativeValidityFormElement | InstanceType<typeof PfFormSelect>;

export function useInputValidity(input: Ref<ValidityFormElement | null>, doValidate?: (() => void)) {
  const isInvalid = ref(false);
  const el = computed(() => {
    if (input.value instanceof HTMLTextAreaElement || input.value instanceof HTMLInputElement || input.value instanceof HTMLSelectElement) {
      return input.value;
    }
    return input.value?.$el as NativeValidityFormElement | null;
  });

  const refreshValidity = () => {
    isInvalid.value = false;
    nextTick(checkValidity);
  };

  const checkValidity = () => {
    doValidate?.();
    isInvalid.value = el.value ? !(el.value.constructor.prototype as NativeValidityFormElement).checkValidity.call(el.value) : true;
    return !isInvalid.value;
  };

  const reportValidity = () => {
    doValidate?.();
    isInvalid.value = el.value ? !(el.value.constructor.prototype as NativeValidityFormElement).reportValidity.call(el.value) : true;
    return !isInvalid.value;
  };

  const setCustomValidity = (validity: string) => {
    el.value?.setCustomValidity(validity);
  };

  onMounted(() => {
    if (el.value) {
      el.value.checkValidity = checkValidity;
      el.value.reportValidity = reportValidity;
    }
  });

  onBeforeUnmount(() => {
    if (el.value) {
      el.value.checkValidity = (el.value.constructor.prototype as NativeValidityFormElement).checkValidity;
      el.value.reportValidity = (el.value.constructor.prototype as NativeValidityFormElement).reportValidity;
    }
  });

  return {
    isInvalid,
    refreshValidity,
    checkValidity,
    reportValidity,
    setCustomValidity,
  };
}
