<template>
  <ul class="radio-wrapper">
    <li class="radio-description" style="width: 100%">
      <label>
        <input
          type="radio"
          name="domainAction"
          :checked="undefined === modelValue"
          @change="$emit('update:modelValue', undefined)"
        >
        {{ $t('No') }}
      </label>
    </li>
    <li class="radio-description" style="width: 100%">
      <label>
        <input
          type="radio"
          name="domainAction"
          :checked="'registrazione' === modelValue"
          @change="$emit('update:modelValue', 'registrazione')"
        >
        {{ $t('Sì, vorrei registrare/trasferire il dominio e attivare i DNS') }}
      </label>
      <p>
        <wizard-price-tag :product="wizard.getProdotto(wizard.codiceProdottoDominio)" />
      </p>
    </li>
    <li class="radio-description" v-if="wizard.path !== 'hosting'" style="width: 100%">
      <label>
        <input
          type="radio"
          name="domainAction"
          :checked="'dns' === modelValue"
          @change="$emit('update:modelValue', 'dns')"
        >
        {{ $t('Vorrei attivare solo il servizio DNS') }}
      </label>
      <p>
        <wizard-price-tag :product="wizard.getProdotto(wizard.codiciProdottiAddon.soloDNS)" />
      </p>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useWizard } from '../composition';
import { type OpzioniDomainAction } from '../wizard.vue';
import WizardPriceTag from '../wizard-price-tag.vue';

defineProps<{
  modelValue: OpzioniDomainAction;
}>();

defineEmits<{
  (name: 'update:modelValue', v: OpzioniDomainAction): void;
}>();

const wizard = useWizard();

</script>
