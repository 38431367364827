<template>
  <login-layout>
    <template v-if="loading">
      <div class="spinner spinner-lg blank-slate-pf-icon" />
      {{ $t('Verifica dell\'indirizzo in corso') }}
    </template>
    <template v-else>
      {{ message }}
    </template>
  </login-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { pop } from '@common/utils';
import LoginLayout from '../../components/login-layout.vue';
import { Resource } from '@/resources';
import axios from 'axios';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'UnsubscribePage',

  components: {
    LoginLayout,
  },

  data() {
    return {
      loading: true,
      message: '',
    };
  },

  async mounted() {
    const email = pop(this.$route.params.mail);
    if (email) {
      try{
        await this.unsubscribe(email);
        this.message = $t(`L'indirizzo {0} è stato disiscritto correttamente dalla newsletter`, [email]);
      } catch (e: any) {
        if (!axios.isAxiosError(e) || e.response?.status !== 404) {
          throw e;
        }
        this.message = $t(`L'indirizzo {0} non è stato trovato`, [email]);
      } finally {
        this.loading = false;
      }
    }
  },

  methods: {
    async unsubscribe(email: string) {
      await new Resource('newsletter_subscription').delete(email);
    },
  },
});
</script>
