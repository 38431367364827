<template>
  <pf-toggle
    :model-value="modelValue"
    :required="required"
    on-value="linux"
    off-value="windows"
    off-text="Windows"
    on-text="Linux"
    off-class="btn-success"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { useWizardQuestion } from '../composition';
import { default as PfToggle } from '../../toggle.vue';
import type { OperativeSystems } from '../wizard.vue';

defineProps<{
  modelValue: OperativeSystems;
  required?: boolean;
}>();

defineEmits<{
  (name: 'update:modelValue', v: boolean): void;
}>();

useWizardQuestion();
</script>
