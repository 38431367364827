<template>
  <login-layout>
    <h2>{{ $t('Pagina non trovata') }}</h2>
    {{ $t('Torna alla') }}
    <router-link :to="{ name: 'dashboard' }">{{ $t('dashboard') }}</router-link>.
  </login-layout>
</template>

<script lang="ts">
import LoginLayout from '../components/login-layout.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotFoundPage',

  components: {
    LoginLayout,
  },
});
</script>
