import { provide, inject, computed, ref, onUpdated, onBeforeUnmount, getCurrentInstance, type ComponentPublicInstance, type Ref, type InjectionKey, type WritableComputedRef, watch, type UnwrapRef, toValue } from 'vue';
import { tryOnMounted, isDefined, type RemovableRef } from '@vueuse/shared';
import { useElementSize, useLocalStorage } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { pop } from './utils';
import type { MaybeReactive, RemovableWritableComputedRef } from './typeutils';

type ChildrenTracker<T extends ComponentPublicInstance> = {
  register: (item: T) => void;
  unregister: (item: T) => void;
}

export type ChildrenTrackerInjectionKey<T extends ComponentPublicInstance = ComponentPublicInstance> = InjectionKey<ChildrenTracker<T>>;

const ChildrenTrackerSymbol = Symbol('Children tracker provide/inject symbol') as ChildrenTrackerInjectionKey<ComponentPublicInstance>;

export function provideChildrenTracker<T extends ComponentPublicInstance = ComponentPublicInstance>(symbol: symbol | ChildrenTrackerInjectionKey<T>) {
  const items: Ref<T[]> = ref([]);

  provide(symbol || ChildrenTrackerSymbol, {
    register: (item: T) => items.value.push(item),

    unregister: (item: T) => {
      const idx = items.value.findIndex(i => i === item);
      if (idx >= 0) {
        items.value.splice(idx, 1);
      }
    },
  });

  return items;
}

export function useChildrenTracker<T extends ComponentPublicInstance>(symbol: symbol | ChildrenTrackerInjectionKey<T>) {
  const tracker = inject(symbol || ChildrenTrackerSymbol, null);

  if (tracker) {
    tryOnMounted(() => {
      const instance = getCurrentInstance();
      if (instance?.proxy) {
        tracker.register(instance.proxy as T);
      }
    });

    onBeforeUnmount(() => {
      const instance = getCurrentInstance();
      if (instance?.proxy) {
        tracker.unregister(instance.proxy as T);
      }
    });
  }

  return tracker;
}

export function useManagedProp<T>(name: string, value: T, onSet?: (to: T) => void): WritableComputedRef<T> {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error('missing component instance');
  }

  const inner = ref(value);

  const watchStop = watch(() => instance.props[name] as T, (newvalue) => {
    inner.value = (isDefined(newvalue) ? newvalue : value) as UnwrapRef<T>;
  });

  onBeforeUnmount(watchStop);

  return computed({
    get(): T {
      return isDefined(instance.props[name]) ? (instance.props[name] as any) : inner.value;
    },
    set(to: T) {
      if (inner.value || !isDefined(instance.props[name])) {
        inner.value = to as any;
      }
      instance.emit(`update:${name}`, to);
      onSet?.(to);
    },
  });
}

export type ElementOverflowOptions = {
  direction?: 'height' | 'width' | 'both';
  watchSize?: boolean;
}

export function useElementOverflow(element: Ref<HTMLElement | undefined>, options?: ElementOverflowOptions): Ref<boolean> {
  const direction = options?.direction ?? 'both';

  const overflowing = ref(false);
  const size = useElementSize(element);

  const testElementOverflow = () => {
    if (!window || !element.value) {
      overflowing.value = false;
      return;
    }

    if (direction === 'both' || direction === 'width') {
      overflowing.value = element.value.scrollWidth > element.value.clientWidth;
      if (overflowing.value) {
        return;
      }
    }

    if (direction === 'both' || direction === 'height') {
      overflowing.value = element.value.scrollHeight > element.value.clientHeight;
    }
  };

  tryOnMounted(testElementOverflow);
  onUpdated(testElementOverflow);

  if (options?.watchSize) {
    watch(() => {
      if (direction === 'both') {
        return size;
      }

      if (direction === 'width') {
        return size.width.value;
      }

      if (direction === 'height') {
        return size.height.value;
      }
    }, testElementOverflow);
  }

  return overflowing;
}

type InitialResettableState = Record<string, any>;
type ResettableState<T> = {
  [K in keyof T]: Ref<T[K]>;
};

export function useResettableState<T extends InitialResettableState>(initialState: T): {
  state: ResettableState<T>,
  reset: () => void,
} {
  const state = {} as ResettableState<T>;
  for (const key in initialState) {
    state[key] = ref(initialState[key]);
  }

  const reset = () => {
    for (const key in initialState) {
      state[key].value = initialState[key];
    }
  };

  return {
    state,
    reset,
  };
}


export function useRouteQueryParam<T extends number | undefined>(name: string, def: T, numeric: true): WritableComputedRef<number | T>;
export function useRouteQueryParam<T extends string | undefined>(name: string, def: T, numeric: false): WritableComputedRef<number | T>;
export function useRouteQueryParam<T extends string | undefined>(name: string, def: T): WritableComputedRef<string | T>;
export function useRouteQueryParam(name: string): WritableComputedRef<string | undefined>;
export function useRouteQueryParam(name: string, def: string | number | undefined = undefined, numeric = false) {
  const router = useRouter();
  const route = useRoute();

  return computed({
    get() {
      const value = pop(route.query[name]);
      if (numeric && isDefined(value)) {
        return Number(value);
      }
      return value ?? def;
    },
    set(value) {
      router.push({
        query: {
          ...route.query,
          [name]: value,
        },
      });
    },
  });
}

export function requireInject<T>(key: InjectionKey<T> | string, errorMessage: string | undefined): T {
  const value = inject(key);
  if (value === undefined) {
    throw new Error(errorMessage ?? 'Missing required injection');
  }
  return value;
}

export function useUserSetting<T>(name: MaybeReactive<string | undefined>, initialValue: T, options: {
  prefix?: MaybeReactive<string | undefined>;
} = {}): RemovableWritableComputedRef<T> {
  const setting = ref(ref(initialValue)) as Ref<RemovableRef<T>>;

  const storageName = computed(() => {
    const prefix = toValue(options.prefix);
    const storageName = toValue(name);
    if ('prefix' in options) {
      return isDefined(prefix) && isDefined(storageName) ? `${prefix}.${storageName}` : undefined;
    }
    return storageName;
  });

  watch(() => [name, options.prefix], () => {
    if (!storageName.value) {
      setting.value = ref(initialValue) as RemovableRef<T>;
      return;
    }
    setting.value = useLocalStorage(storageName.value, initialValue, {
      listenToStorageChanges: false,
    });
  }, { immediate: true });

  return computed({
    get: () => toValue(toValue(setting)) ?? initialValue,
    set: (value: T | undefined) => toValue(setting).value = value,
  }) as RemovableWritableComputedRef<T>;
}
