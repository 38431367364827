<template>
  <page
    v-model:filter="q"
    :name="$t('My hosting')"
    :empty="services.length <= 0"
    :state="asyncComputed"
    with-filter
  >
    <pf-list-view v-slot="{ row, index }" :rows="filtered" :items-per-page="0" key-name="dominio" stacked>
      <service-detail
        v-model:pannello="row.pannello"
        v-model:posta="row.posta"
        v-model:redirect="row.redirect"
        :index="index"
        :dominio="row.dominio"
        :pecs="row.pecs"
        :dns="row.dns"
        :is-registrazione-dominio="row.is_registrazione_dominio"
      />
    </pf-list-view>
  </page>
</template>

<script lang="ts">
import { ServiceResource } from '@/resources';
import { setupAsyncComputed } from '@common/asyncComputed';
import { any } from '@common/utils';
import Page from '../components/page.vue';
import ServiceDetail from '../components/service-detail.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ServicesPage',

  components: {
    Page,
    ServiceDetail,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        services: {
          get() {
            return new ServiceResource().get();
          },
          default: [],
        },
      }),
    };
  },

  data() {
    return {
      q: '', // search query
    };
  },

  computed: {
    filtered() {
      return this.services.filter(any(this.q));
    },
  },
});
</script>
