<template>
  <pf-dropdown :text="text">
    <li>
      <a role="menuitem" @click.prevent="updateCurrency('EUR')"> EUR </a>
    </li>
    <li>
      <a role="menuitem" @click.prevent="updateCurrency('CHF')"> CHF </a>
    </li>
  </pf-dropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useLoginStore } from '@/store/login';
import { http } from '@/http';

export default defineComponent({
  name: 'CurrencyToggle',

  props: {
    prefix: {
      type: String,
      default: null,
    },
  },

  computed: {
    text() {
      const login = useLoginStore();
      if (!this.prefix) {
        return login.currency;
      }
      return `${this.prefix}: ${login.currency}`;
    },
  },

  methods: {
    async updateCurrency(currency: 'EUR' | 'CHF') {
      const data = new FormData();
      data.append('currency', currency);
      await http.post('/api/currency/update', data);

      const login = useLoginStore();
      login.currency = currency;
    },
  },
});
</script>
