<template>
  <h3>{{ _t('Pagamento') }}</h3>
  <div v-if="cashbackAvailable && cashbackState.state === 'success'" style="max-width: 800px">
    <ul>
      <li class="row">
        <span class="col-xs-12 col-sm-4">{{ $t('Cashback disponibile') }}:</span>
        <span class="col-xs-12 col-sm-2 value-cell">{{ $currency(-ammontareCashbackAzienda, currency) }}</span>
      </li>
      <li class="row">
        <span class="col-xs-12 col-sm-4">{{ $t('Cashback utilizzabile per questa offerta') }}:</span>
        <span class="col-xs-12 col-sm-2 value-cell">{{ $currency(- maxCashback, currency) }}</span>
      </li>
      <li>
        <label><input v-model="useCashBack" type="checkbox" name="use_cashback" @change="change"> {{ $t('Utilizza il cashback') }}</label>
        <input v-if="useCashBack" type="hidden" :value="maxCashback" name="cashback_amount">
      </li>
    </ul>
    <ul>
      <li class="row">
        <span class="col-xs-12 col-sm-4"><strong>{{ $t('Totale Fattura (IVA Inclusa)') }}:</strong></span>
        <span class="col-xs-12 col-sm-2 value-cell"><strong>{{ $currency(grandTotal, currency) }}</strong></span>
      </li>
    </ul>
  </div>
  <div v-else-if="cashbackState.state !== 'success'" class="blank-slate-pf">
    <div class="spinner spinner-lg blank-slate-pf-icon" />
    <h3 class="blank-slate-pf-main-action">
      {{ $t('Verifica conto Artera') }}
    </h3>
  </div>
</template>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

.value-cell {
  text-align: right;
}
</style>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useLoginStore } from '@/store/login';
import { CashbackResource } from '@/resources';
import { asyncComputed } from '@common/asyncComputed';
import { $t as _t } from '@/i18n';

const { result: ammontareCashbackAzienda, state: cashbackState } = asyncComputed(async() => {
  if (!login.is_reseller) {
    return 0;
  }
  const cashback = await new CashbackResource().get(1);
  return cashback.amount;
});

const props = defineProps<{
  modelValue: number;
  totaleOffertaIvato: number;
  currency: 'CHF' | 'EUR';
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>();

const login = useLoginStore();
const useCashBack = ref();

const cashbackAvailable = computed(() => login.is_reseller && ammontareCashbackAzienda.value > 0);

const maxCashback = computed(() =>  {
  if (props.totaleOffertaIvato - ammontareCashbackAzienda.value >= 10) {
    return ammontareCashbackAzienda.value;
  }
  return Math.max(props.totaleOffertaIvato - 10, 0);
});

const grandTotal = computed(() => props.totaleOffertaIvato - (useCashBack.value ? maxCashback.value : 0));

function change(){
  emit('update:modelValue', useCashBack.value ? maxCashback.value : 0);
}
</script>
