import axios from 'axios';
import { useAppStore } from '@/store/app';
import { $t } from '@/i18n';

export const http = axios.create({
  withCredentials: true,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

http.interceptors.request.use(config => {
  const app = useAppStore();
  app.loading++;
  return config;
});

http.interceptors.response.use(
  response => {
    const app = useAppStore();
    app.loading--;

    if (response.data?.message) {
      const type = response.data.level || 'info';
      app.toast(response.data.message, type);
    }

    return response;
  },

  error => {
    const app = useAppStore();
    app.loading--;

    if (error.response) {
      const code = error.response.status;
      const data = error.response.data || {};
      let message: string | undefined = '';
      if (typeof data === 'string') {
        if (data.substring(0, 1) !== '<') {
          message = data;
        }
      } else {
        message = data.message || data.reason || data.description;
      }
      if (code === 401) {
        if (app.route()?.meta.requiresAuth) {
          app.logout(true);
        }
      } else if (code == 403 && typeof data === 'object' && data.code == 10) {
        app.toast($t('Utente non autorizzato'), 'warning');
      } else if (code >= 400 && code < 500 && message) {
        app.toast(message, [400, 409, 422].includes(code) ? 'warning' : 'danger');
      } else if (code == 503) {
        app.maintenance = true;
      } else if (code >= 500) {
        app.toast(error.response.statusText || $t("Errore non previsto. Invia una mail a support{'@'}artera.net per assistenza."), 'danger');
      }
    }
    return Promise.reject(error);
  },
);
