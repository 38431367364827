<template>
  <x-form :hidden="hidden" :submit-text="nextLabel" @submit="wizard.nextStep">
    <h2>{{ title }}</h2>

    <template #submit>
      {{ nextLabel }}
      <angle-right-icon />
    </template>

    <template #actions>
      <slot name="actions">
        <pf-button
          v-if="confirm"
          variant="success"
          href="#totali"
        >{{ $t('Concludi') }}</pf-button>

        <pf-button :disabled="previousDisabled" @click="wizard.previousStep">
          <angle-left-icon />
          {{ previousLabel }}
        </pf-button>

        <pf-button
          class="btn-cancel"
          variant="default"
          @click="wizard.resetWizard(false)"
        >{{ $t('Reset') }}</pf-button>
      </slot>
    </template>

    <div class="form">
      <slot />
    </div>
  </x-form>
</template>

<style lang="scss">
.wizard-pf-body {
  fieldset {
    display: flex;
    flex-direction: column
  }

  .form {
    order: 2;
  }

  .form-footer {
    flex-direction: row-reverse;
    align-items: center;
    order: 1;

    .btn-cancel {
      margin-right: 15px;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { $t } from '../../i18n';
import XForm from '@/components/x-form.vue';
import { useWizard } from './composition';
import AngleLeftIcon from '@vue-patternfly/icons/angle-left-icon';
import AngleRightIcon from '@vue-patternfly/icons/angle-right-icon';

export default defineComponent({
  name: 'WizardForm',

  components: {
    XForm,
    AngleLeftIcon,
    AngleRightIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    hidden: Boolean,
    previousDisabled: Boolean,
    nextHidden: Boolean,
    nextLabel: {
      type: String,
      default: $t('Avanti'),
    },
    previousLabel: {
      type: String,
      default: $t('Indietro'),
    },
    confirm: Boolean,
  },

  setup() {
    return {
      wizard: useWizard(),
    };
  },
});
</script>
