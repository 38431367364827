<template>
  <h2>{{ $t("Errore durante il pagamento") }}</h2>
  <i18n-t keypath="C'è stato un errore durante il pagamento PayPal. Ti chiediamo cortesemente di riprovare o di scriverci una mail a {0}.">
    <a href="mailto:finance@artera.net">finance@artera.net</a>
  </i18n-t>
</template>

<script lang="ts" setup>
import { usePaypalStore } from '@/store/paypal';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

onBeforeMount(() => {
  const paypal = usePaypalStore();

  if (!paypal.shopTransactionType || !paypal.shopTransactionContentId) {
    return;
  }

  router.push({
    name: paypal.shopTransactionType,
    params: {id: paypal.shopTransactionContentId},
    query: {res: 1},
  });
});
</script>
