import { defineComponent, type App, isVNode, type VNode, type VNodeNormalizedChildren, Fragment } from 'vue';

import Pf3Icon from './Pf3Icon.vue';
import PfDataList from './PfDataList.vue';
import PfDataListItem from './PfDataListItem.vue';
import PfDataListCell from './PfDataListCell.vue';
import PfDataListContent from './PfDataListContent.vue';
import PfInputGroup from './PfInputGroup.vue';
import PfFormSelect from './PfFormSelect.vue';
import PfFormSelectOption from './PfFormSelectOption.vue';
import PfSelect from './PfSelect.vue';
import PfSelectOption from './PfSelectOption.vue';
import PfSpinner from './PfSpinner.vue';
import PfSwitch from './PfSwitch.vue';
import PfTab from './PfTab.vue';
import PfTabs from './PfTabs.vue';
import PfToolbarItem from './PfToolbarItem.vue';
import PfTooltip from './PfTooltip.vue';

export function findChildrenVNodes(vnodes: VNode[] | VNodeNormalizedChildren | undefined): VNode[] {
  if (!Array.isArray(vnodes)) {
    return [];
  }

  return vnodes
    .filter(n => isVNode(n) && n.type !== Comment)
    .map(n => isVNode(n) && n.type === Fragment ? findChildrenVNodes(n.children) : n)
    .flat() as VNode[];
}

// Declare install function executed by Vue.use()
export function install(app: App) {
  delete app._context.components.PfSelect;
  delete app._context.components.PfSpinner;
  delete app._context.components.PfTooltip;

  app.component('Pf3Icon', Pf3Icon);
  app.component('PfDataList', PfDataList);
  app.component('PfDataListItem', PfDataListItem);
  app.component('PfDataListCell', PfDataListCell);
  app.component('PfDataListContent', PfDataListContent);
  app.component('PfInputGroup', PfInputGroup);
  app.component('PfFormSelect', PfFormSelect);
  app.component('PfFormSelectOption', PfFormSelectOption);
  app.component('PfSelect', PfSelect);
  app.component('PfSelectOption', PfSelectOption);
  app.component('PfSpinner', PfSpinner);
  app.component('PfSwitch', PfSwitch);
  app.component('PfTab', PfTab);
  app.component('PfTabs', PfTabs);
  app.component('PfToolbarItem', PfToolbarItem);
  app.component('PfTooltip', PfTooltip);
}

// Create module definition for Vue.use()
const plugin = {
  install,
};

type DisabledComponent = ReturnType<typeof defineComponent<{
  componentDisabled: {
    type: BooleanConstructor;
    required: true;
  };
}>>;

export default plugin;

declare module 'vue' {
  export interface GlobalComponents {
    PfIcon: DisabledComponent;
    Pf3Icon: typeof Pf3Icon;
    PfInputGroup: typeof PfInputGroup;
    PfDataList: typeof PfDataList;
    PfDataListItem: typeof PfDataListItem;
    PfDataListCell: typeof PfDataListCell;
    PfDataListContent: typeof PfDataListContent;
    PfFormSelect: typeof PfFormSelect;
    PfFormSelectOption: typeof PfFormSelectOption;
    PfSelect: typeof PfSelect;
    PfSelectOption: typeof PfSelectOption;
    PfSpinner: typeof PfSpinner;
    PfSwitch: typeof PfSwitch;
    PfTab: typeof PfTab;
    PfTabs: typeof PfTabs;
    PfToolbarItem: typeof PfToolbarItem;
    PfTooltip: typeof PfTooltip;
  }
}
