<template>
  <page :name="$t('Partner')" :description="$t('Compila il form e diventa partner Artera')">
    <div class="partner-wrapper">
      <div class="partner-description">
        <p>{{ $t('Compila questo form indicandoci le tue preferenze per diventare Partner Artera') }}</p>
      </div>

      <div class="partner-form">
        <form role="form" @submit.prevent="send">
          <div class="row">
            <div class="form-group col-lg-3 col-sm-6">
              <label for="partnerModalita">{{ $t('Modalità') }} *
                &nbsp;
                <form-help-tooltip :text="$t('In modalità \'Freelance\' saremo noi a fatturare ai tuoi clienti e tu riceverai una provvigione per ogni acquisto fatto dai tuoi clienti. In modalità \'whitelabel\' i servizi saranno intestati a te e fatturati a prezzo scontato.')" />
              </label>
              <select v-model="partner.modalita" name="modalita" class="form-control" required>
                <option value="whitelabel">
                  {{ $t('Whitelabel') }}
                </option>
                <option value="freelance">
                  {{ $t('Freelance') }}
                </option>
                <option value="whitelabel-freelance">
                  {{ $t('Whitelabel e Freelance') }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-3 col-sm-6">
              <label>
                {{ $t('Fatturazione mensile') }}
                &nbsp;
                <form-help-tooltip :text="$t('Attivando questa opzione riceverai al massimo una fattura al mese, alla fine del mese, che comprenderà tutti i servizi rinnovati in quel mese.')" />
              </label>
              <div>
                <toggle v-model="partner.fatturazione_mensile" name="fatturazione_mensile" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-3 col-sm-6">
              <label>
                {{ $t('Sconto provvigioni in fattura') }}
                &nbsp;
                <form-help-tooltip :text="$t('Attivando questa opzione le provvigioni maturate e caricate come \'Credito Artera\' verranno scalate automaticamente in fattura')" />
              </label>
              <div>
                <toggle v-model="partner.provvigioni_in_fattura" name="provvigioni_in_fattura" />
              </div>
            </div>
          </div>

          <button type="submit" :disabled="sending" class="btn btn-primary">
            {{ $t('Invia') }}
          </button>
        </form>
      </div>
    </div>
  </page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Page from '../components/page.vue';
import Toggle from '../components/toggle.vue';
import { useLoginStore } from '@/store/login';
import { http } from '../http';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

export default defineComponent({
  name: 'BecomePartnerPage',

  components: {
    Page,
    Toggle,
    FormHelpTooltip,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
    };
  },

  data() {
    return {
      partner: {
        modalita: null as 'whitelabel' | 'freelance' | 'whitelabel-freelance' | null,
        fatturazione_mensile: true,
        provvigioni_in_fattura: true,
      },
      sending: false,
    };
  },

  methods: {
    async send(e: Event) {
      if (!(e.target instanceof HTMLFormElement)) {
        return;
      }

      this.sending = true;

      try {
        const data = new FormData(e.target);
        await http.post('/api/become_partner/update', data);

        this.partner = {
          modalita: null,
          fatturazione_mensile: true,
          provvigioni_in_fattura: true,
        };

        await this.login.refresh();
      } finally {
        this.sending = false;
      }

      this.$router.push('/');
    },

  },

});
</script>
