import { unref } from 'vue';
import { defineStore } from 'pinia';
import { useAppStore } from '@/store/app';
import { pop } from '@common/utils';

export const usePaypalStore = defineStore('paypal', {
  state() {
    return {
      shopTransactionType: null as string | null,
      shopTransactionContentId: null as string | null,
    };
  },

  actions: {
    save() {
      const app = useAppStore();
      const route = unref(app.router?.currentRoute);
      if (!route) {
        return;
      }
      const id = pop(route.params.id);
      const name = route.name?.toString();

      if (!id || !name) {
        return;
      }

      try {
        localStorage.setItem('shopTransactionType', name);
        localStorage.setItem('shopTransactionContentId', id);
      } catch (e) {}
    },

    load() {
      try {
        this.shopTransactionType = localStorage.getItem('shopTransactionType');
        localStorage.removeItem('shopTransactionType');
      } catch (e) {}

      try {
        this.shopTransactionContentId = localStorage.getItem('shopTransactionContentId');
        localStorage.removeItem('shopTransactionContentId');
      } catch (e) {}
    },
  },
});
