p<template>
  <ul class="radio-wrapper">
    <li v-for="(v, k) of opzioniFarm" :key="k">
      <label>
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="k"
          :checked="k === modelValue"
          :disabled="isBackup && k === wizard.sceltaFarmProduzione"
          @change="$emit('update:modelValue', k as ValueType)"
        >
        <span :class="{farm_disabled: isBackup && k === wizard.sceltaFarmProduzione}"><template v-if="k !== 'altro'">&nbsp;{{ emojiflag(k.slice(0,2)) }}</template> {{ v }}</span>
      </label>
    </li>
  </ul>
</template>

<style>
.farm_disabled {
  opacity: 0.5;
}
</style>

<script setup lang="ts">
import { emojiflag } from '@common/utils';
import { useWizardQuestion } from '../composition';
import type { WizardInjection } from '../wizard.vue';

type ValueType = WizardInjection['sceltaFarmBackup'] | WizardInjection['sceltaFarmMondo'] | WizardInjection['sceltaFarmProduzione'];

defineProps<{
  modelValue: ValueType;
  name: string;
  required?: boolean;
  opzioniFarm: Record<string, string>;
  isBackup?: boolean;
}>();

const { wizard } = useWizardQuestion();

defineEmits<{
  (name: 'update:modelValue', v: ValueType): void;
}>();

useWizardQuestion();
</script>
