<template>
  <component :is="fake ? 'div' : 'form'" ref="form" :method="method" :action="action" :hidden="hidden" novalidate @submit="submit">
    <fieldset ref="fieldset" :disabled="disabled || undefined">
      <slot />

      <pf-notifications>
        <pf-notification
          v-for="(error, i) in submitErrors"
          :key="i"
          :type="error.type as NotificationType"
          persistent
          @dismiss="submitErrors.splice(i, 1)"
        >
          {{ error.message }}
        </pf-notification>
      </pf-notifications>

      <div v-if="submitText || $slots.actions" class="form-footer">
        <pf-button
          v-if="submitText"
          type="submit"
          variant="primary"
          :class="{ disabled: !valid }"
          :disabled="effectiveDisabled || preventSubmit"
          @click="validate"
        >
          <slot name="submit">{{ submitText }}</slot>
          <pf-spinner v-if="sending" inline size="sm" />
        </pf-button>
        {{ ' ' }}
        <slot name="actions" />
        {{ ' ' }}
        <em v-if="submitHelp">{{ submitHelp }}</em>
      </div>
    </fieldset>
  </component>
</template>

<style lang="scss">
.form-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>

<script lang="ts">
import { $t } from '@/i18n';
import { defineComponent } from 'vue';
import { XFormMixin, useXForm } from '@common/form';

type NotificationType = 'info' | 'success' | 'warning' | 'danger';

export default defineComponent({
  name: 'XForm',

  mixins: [XFormMixin],

  props: {
    submitText: {
      type: String,
      default: $t('Procedi'),
    },

    submitHelp: {
      type: String,
      default: '',
    },

    hidden: Boolean,
    disabled: Boolean,
  },

  emits: {
    submit: (e: Event | undefined, responseData: unknown) => true,
  },

  setup(props) {
    return {
      ...useXForm(props),
    };
  },
});
</script>
