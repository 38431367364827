<template>
  <select v-bind="{...$attrs, ...ouiaProps}" ref="$el" v-model="value" class="form-control">
    <slot />
  </select>
</template>

<script lang="ts" setup>
import { ref, type Ref, type SelectHTMLAttributes } from 'vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useManagedProp } from '../use';

export interface Props extends OUIAProps, /* @vue-ignore */ SelectHTMLAttributes {
  modelValue?: string;
}

const props = defineProps<Props>();

defineEmits<{
  (name: 'update:modelValue', value: string): void;
}>();

const $el: Ref<HTMLSelectElement | undefined> = ref();
const ouiaProps = useOUIAProps(props);
const value = useManagedProp('modelValue', '');

defineExpose({
  focus: () => { $el.value?.focus(); },
});
</script>
