<template>
  <page
    :name="$t('Offerte e ordini')"
    :description="$t('Qui puoi trovare tutti i nostri preventivi e le offerte generate da wizard')"
    :empty-text="$t('Nessun offerta disponibile')"
    :empty="!offers.confermare.length && !offers.rifiutate.length && !offers.ordini.length"
    :state="asyncComputed"
    :loading="loadingConfermare || loadingRifiutate"
    hide
  >
    <template v-if="offers.confermare.length">
      <h2 id="offerte-da-confermare">
        <file-pdf-icon class="text-primary" /> {{ $t('Offerte da confermare') }}
      </h2>
      <offers-list
        v-model:loading="loadingConfermare"
        :offerte="offers.confermare"
        @change-state="asyncComputed.offers.update"
      />
    </template>

    <template v-if="offers.ordini.length">
      <h2 id="ordini-confermati">
        <ok-icon /> {{ $t('Ordini confermati') }}
      </h2>
      <offers-list
        :offerte="offers.ordini"
        @change-state="asyncComputed.offers.update"
      />
    </template>

    <template v-if="offers.rifiutate.length">
      <h2 id="offerte-rifiutate">
        <circle-exclamation-icon /> {{ $t('Offerte rifiutate') }}
      </h2>
      <offers-list
        v-model:loading="loadingRifiutate"
        :offerte="offers.rifiutate"
        @change-state="asyncComputed.offers.update"
      />
    </template>
  </page>
</template>

<style scoped>
h2 {
  margin-top: 30px;
  margin-bottom: 0;
}
</style>

<script lang="ts">
import Page from '../components/page.vue';
import OffersList from '../components/offers-list.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { type Offer, OffersResource } from '@/resources';
import { defineComponent } from 'vue';
import OkIcon from '@vue-patternfly/icons/ok-icon';
import CircleExclamationIcon from '@vue-patternfly/icons/circle-exclamation-icon';
import FilePdfIcon from '@vue-patternfly/icons/file-pdf-icon';

export default defineComponent({
  name: 'OffersPage',

  components: {
    Page,
    OffersList,
    OkIcon,
    CircleExclamationIcon,
    FilePdfIcon,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        offers: {
          async get(this: any) {
            const offers = await new OffersResource().get();

            const groupedOffers = {
              confermare: [] as Offer[],
              rifiutate: [] as Offer[],
              ordini: [] as Offer[],
            };
            for (const offerta of offers) {
              if (!offerta.stato_offerta && offerta.stato_ordine) {
                groupedOffers.ordini.push(offerta);
                continue;
              }

              switch (offerta.stato_offerta) {
                case 'inviata':
                case 'sollecitata':
                case 'stand_by':
                  groupedOffers.confermare.push(offerta);
                  break;
                case 'rifiutata':
                  groupedOffers.rifiutate.push(offerta);
                  break;
                default:
                  break;
              }
            }
            return groupedOffers;
          },
          default: {
            confermare: [],
            rifiutate: [],
            ordini: [],
          },
        },
      }),
    };
  },

  data() {
    return {
      loadingConfermare: false,
      loadingRifiutate: false,
    };
  },
});
</script>
