<template>
  <div role="main">
    <ol
      v-if="name && !noBreadcrumbs && !$route.meta.disableNavigation"
      role="navigation"
      aria-label="breadcrumbs"
      class="breadcrumb"
    >
      <router-link v-slot="{ navigate }" :to="{ name: 'dashboard', params: { id_cliente: company.id } }" custom>
        <li>
          <a @click="navigate">Dashboard</a>
        </li>
      </router-link>
      <slot name="breadcrumbs" />
      <li class="active">{{ name }}</li>
    </ol>

    <div v-if="failed && !ignoreFailed" role="alert" class="alert alert-danger">
      <circle-exclamation-icon />
      {{ errorText }}
    </div>

    <div v-else-if="updating" class="blank-slate-pf" :class="{ 'only-blank-slate-pf': noBreadcrumbs }">
      <div class="spinner spinner-lg blank-slate-pf-icon" />

      <slot name="loading">
        <h3 class="blank-slate-pf-main-action">{{ loadingText }}</h3>
      </slot>
    </div>

    <div v-else-if="empty" class="blank-slate-pf" :class="{ 'only-blank-slate-pf': noBreadcrumbs }">
      <h1>{{ pageTitle }}</h1>

      <slot name="empty">
        <p>{{ emptyText }}</p>
      </slot>
    </div>

    <template v-else>
      <div class="heading row" :class="{ sticky }">
        <div :class="{ 'col-md-4': withFilter || $slots.middle, 'col-lg-12': !withFilter && !$slots.middle }">
          <h1>
            {{ pageTitle }}
            <small v-if="description">{{ description }}</small>
          </h1>
        </div>

        <div v-if="$slots.middle" :class="{ 'col-md-6': withFilter, 'col-md-8': !withFilter }">
          <slot name="middle" />
        </div>

        <div v-if="withFilter" class="title-search" :class="{ 'col-md-2': $slots.middle, 'col-md-8': !$slots.middle }">
          <div class="form-search search-only">
            <i class="icon icon-search search-icon" />
            <input
              type="search"
              class="form-control search-query"
              :value="filter"
              :placeholder="$t('Filtra')"
              @input="onInput"
            >
          </div>
        </div>
      </div>

      <template v-if="!hide">
        <slot />
      </template>
    </template>

    <template v-if="hide">
      <div v-show="(!failed || ignoreFailed) && !updating && !empty">
        <slot />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
[role='main'] > .alert,
.only-blank-slate-pf {
  margin-top: 20px;
}

[role='main'] {
  margin-bottom: 20px;
}

.breadcrumb + .heading {
  h1,
  .h1,
  h2,
  .h2 {
    margin-top: 0;
  }
}

.sticky {
  position: sticky;
  top: 60px;
  z-index: 2;
  background: white;
  padding: 10px 0;
}

.heading {
  display: flex;
  align-items: baseline;

  @media(max-width: 992px) {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>

<script lang="ts">
import { $t } from '@/i18n';
import { useCompanyStore } from '@/store/company';
import { AsyncComputedState } from '@common/asyncComputed';
import debounce from 'lodash-es/debounce';
import { defineComponent, type PropType } from 'vue';
import CircleExclamationIcon from '@vue-patternfly/icons/circle-exclamation-icon';

export interface PageState {
  state: AsyncComputedState;
}

function isPageState(s: any): s is PageState {
  return typeof s === 'object' && typeof s.state === 'string';
}

export default defineComponent({
  name: 'Page',

  components: {
    CircleExclamationIcon,
  },

  props: {
    name: String,
    title: String,
    description: String,
    hideBreadcrumbs: Boolean,
    empty: Boolean,
    emptyText: {
      type: String,
      default: $t('Nessun elemento presente in questa sezione.'),
    },
    loading: Boolean,
    loadingText: {
      type: String,
      default: $t('Caricamento in corso'),
    },
    error: Boolean,
    errorText: {
      type: String,
      default: $t('Si è verificato un problema inaspettato. Riprovare più tardi.'),
    },
    withFilter: Boolean,
    filter: String,
    state: {
      type: [Array, Object] as PropType<PageState | PageState[] | Record<string, PageState>>,
      default: (): PageState[] => [],
    },
    ignoreFailed: Boolean,
    sticky: Boolean,
    hide: Boolean,
  },

  emits: ['update:filter'],

  setup() {
    const company = useCompanyStore();
    return {
      company,
    };
  },

  computed: {
    pageTitle() {
      return typeof this.title == 'undefined' ? this.name : this.title;
    },

    updating() {
      return this.loading || this.checkState(AsyncComputedState.Updating);
    },

    failed() {
      return this.error || this.checkState(AsyncComputedState.Error);
    },

    noBreadcrumbs() {
      return this.hideBreadcrumbs || this.$route.meta.disableNavigation;
    },
  },

  watch: {
    updating: {
      handler(updating) {
        this.$nextTick(() => (document.body.dataset.ouiaPageSafe = updating ? 'false' : 'true'));
      },
      immediate: true,
    },
  },

  created() {
    this.onInput = debounce((e: Event) => {
      if (e.target instanceof HTMLInputElement) {
        this.$emit('update:filter', e.target.value);
      }
    }, 300);
  },

  methods: {
    checkState(stateValue: AsyncComputedState) {
      let states = this.state;
      if (!Array.isArray(states)) {
        if (isPageState(states)) {
          states = [states];
        } else {
          states = Object.values(states);
        }
      }
      return states.findIndex(v => v.state === stateValue) >= 0;
    },

    // specificato solo per definire il metodo nel tipo typescript. viene sovrascritto in created().
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onInput: (e: Event) => { return; },
  },
});
</script>
