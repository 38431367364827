<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Scelta dello stack')">
    <div class="row">
      <div v-if="wizard.askStack" class="col-md-6">
        <h3>{{ $t('Quale stack vuoi attivare?') }}</h3>
        <label>
          <input v-model="wizard.stack" type="radio" name="stack" value>
          {{ $t('Nessuno') }}
        </label>
        <p>{{ $t("Il server verrà fornito senza la configurazione di backup, monitoraggio e senza supporto tecnico.") }}</p>
        <wizard-question-stack
          v-model="wizard.stack"
          required
        />
      </div>
      <div v-if="wizard.askEssentialOs" class="col-md-6">
        <h3>{{ $t('Sistema operativo') }}</h3>
        <p>{{ $t('Quale sistema operativo vorresti che ti venisse installato?') }}</p>
        <input
          v-model="wizard.essentialOs"
          type="text"
          name="essentialOs"
          placeholder="Sistema operativo"
          required
        >
      </div>
      <div v-else-if="wizard.askCockpitConfig" class="col-md-6">
        <h3>{{ $t('Sistema operativo') }}</h3>
        <p>{{ $t('Quale sistema operativo vorresti che ti venisse installato?') }}</p>
        <wizard-question-options
          v-model="wizard.cockpitOs"
          :options="wizard.cockpitOperativeSystems"
          name="cockpitOs"
          required
        />

        <h3>{{ $t('Web Server') }}</h3>
        <p>{{ $t('Quale web server vorresti che ti venisse installato?') }}</p>
        <wizard-question-options
          v-model="wizard.cockpitWs"
          :options="wizard.webServers"
          name="cockpitWs"
          required
        />
      </div>
      <div v-else-if="wizard.askAddonCpanel">
        <div>
          <h3>{{ $t('Vuoi attivare una licenza Imunify360?') }}</h3>
          <wizard-question-prodotto
            v-model="wizard.addonImunify"
            :product="wizard.getProdotto(wizard.codiceProdottoImunify)"
            show-prezzo
            show-utenti
          />
        </div>
        <div>
          <h3>{{ $t('Vuoi attivare una licenza LiteSpeed?') }}</h3>
          <wizard-question-prodotto
            v-model="wizard.addonLitespeed"
            :product="wizard.getProdotto(wizard.codiciProdottiAddon.liteSpeed)"
            show-prezzo
          />
        </div>
      </div>
    </div>
  </wizard-form>
</template>

<style scoped>
.question-wrapper {
  display: flex;
}
</style>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardQuestionStack from '../questions/wizard-question-stack.vue';
import WizardQuestionOptions from '../questions/wizard-question-options.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepStack',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askStack,
});

defineExpose({
  isEnabled,
});
</script>
