<template>
  <section>
    <template v-if="asyncComputed.invoice.updating">
      <div class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />

        <slot name="loading">
          <h3 class="blank-slate-pf-main-action">
            {{ $t('Caricamento in corso') }}
          </h3>
        </slot>
      </div>
    </template>

    <table v-else class="table">
      <thead>
        <tr>
          <th>{{ $t('Descrizione') }}</th>
          <th>{{ $t('Qta') }}</th>
          <th>{{ $t('Prezzo') }}</th>
          <th>{{ $t('Sconto') }}</th>
          <th>{{ $t('IVA') }}</th>
          <th>{{ $t('Importo') }}</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colspan="5" class="text-right">
            {{ $t('Imponibile') }}:<br>
            {{ $t('Imposta') }}:<br>
            <b>{{ $t('Totale') }}:</b>
          </td>
          <th>
            {{ $currency(invoice.amount, invoice.currency) }}<br>
            {{ $currency(invoice.vatamount, invoice.currency) }}<br>
            {{ $currency(invoice.amount + invoice.vatamount, invoice.currency) }}
          </th>
        </tr>
      </tfoot>
      <tbody>
        <tr v-for="row in invoice.rows" :key="row.id">
          <td>{{ row.description }}</td>
          <td>{{ row.qt }}</td>
          <td>{{ $currency(row.price, invoice.currency) }}</td>
          <td>{{ $currency(row.discount) }}%</td>
          <td>{{ row.vat }}%</td>
          <td>{{ $currency(row.amount, invoice.currency) }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script lang="ts">
import { Resource, type GenericResource } from '@/resources';
import { setupAsyncComputed } from '@common/asyncComputed';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InvoiceDetail',

  props: {
    invoiceId: {
      type: Number,
      required: true,
    },
  },

  setup() {
    return {
      ...setupAsyncComputed({
        invoice: {
          get(this: any) {
            return new Resource('invoices').get(this.invoiceId);
          },
          default: {} as GenericResource,
        },
      }),
    };
  },
});
</script>
