<template>
  <render />
</template>

<script lang="ts" setup>
import type { VNodeArrayChildren } from 'vue';
import { isVNode } from 'vue';
import { type VNode } from 'vue';

const slots = defineSlots<{
  default: (props: Record<never, never>) => VNode[];
}>();

const emit = defineEmits<{
  (name: 'change', value: string): void;
}>();

function findText(nodes: VNodeArrayChildren): string | undefined {
  for (const node of nodes) {
    if (!isVNode(node)) {
      continue;
    }
    if (typeof node.children == 'string') {
      return node.children;
    }
    if (Array.isArray(node.children)) {
      return findText(node.children);
    }
  }
}

function render() {
  const content = slots.default({});
  if (content.length > 1) {
    throw new Error("CaptureText can only contain a single child node");
  }
  const text = findText(content);
  emit('change', text ?? '');
}
</script>
