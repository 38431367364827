<template>
  <h2 v-if="title">{{ title }}</h2>
  <pf-list-view :rows="documents" :items-per-page="0" key-name="name" stacked>
    <template #default="{ row }">
      <pf-list-item>
        <template #left>
          <span class="fa list-view-pf-icon-md">
            <file-pdf-icon />
          </span>
        </template>

        <template #heading>
          <span :title="row.name">{{ row.name }}</span>
        </template>

        <template #description>
          <p>{{ row.description }}</p>
        </template>
      </pf-list-item>
    </template>

    <template #action="{ row }">
      <div>
        <a v-if="row.link" :href="row.link" target="_blank" class="btn btn-primary">{{ label }}</a>
        <pf-button v-else-if="row.contract" class="btn btn-primary" @click="showContract = row.contract">{{ _t('Leggi') }}</pf-button>
      </div>
    </template>
  </pf-list-view>

  <pf-modal
    :show="!!showContract"
    outside-close
    confirm-button=""
    :cancel-button="_t('Chiudi')"
    @cancel="showContract=undefined">
    <template v-if="contract">
      <h2>{{ contract.titolo }}</h2>
      <iframe class="terms" referrerpolicy="no-referrer" sandbox="" :srcdoc="contract.testo" width="100%" height="600px" />
    </template>
    <div v-else class="blank-slate-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />
      <h3 class="blank-slate-pf-main-action">
        {{ _t('Caricamento termini e condizioni') }}
      </h3>
    </div>
  </pf-modal>
</template>

<script lang="ts" setup>
import { type Ref, ref } from 'vue';
import { $t as _t } from '@/i18n';
import { ContractResource } from '@/resources';
import { asyncComputed } from '@common/asyncComputed';
import FilePdfIcon from '@vue-patternfly/icons/file-pdf-icon';

export type Document = {
  name: string;
  description: string;
  link?: string;
  contract?: string;
};

const showContract: Ref<'cliente' | 'reseller' | undefined> = ref();

withDefaults(defineProps<{
  title: string;
  label?: string;
  documents: Document[];
}>(), {
  label: _t('Scarica'),
});

const { result: contract } = asyncComputed(async() => {
  if (showContract.value !== undefined) {
    const contract = await new ContractResource().get(showContract.value);
    contract.testo = `<html><head><style>
body {
  zoom: 80%;
  margin: 10px;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}
</style></head>
<body>${contract.testo}</body></html>`;
    return contract;
  }
});
</script>
