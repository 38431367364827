<template>
  <p>Le chiavi SSH permettono di stabilire una connessione sicura al server.</p>
  <template v-if="loading">
    <div class="blank-slate-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />

      <slot name="loading">
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Caricamento delle chiavi in corso...') }}
        </h3>
      </slot>
    </div>
  </template>
  <div v-else style="max-width: 800px;">
    <h3>{{ $t('Aggiungi una chiave SSH') }}</h3>
    <textarea v-model="newKey" name="ssh_key" placeholder="Chiave pubblica in formato OpenSSH" style="width: 100%; height:125px" />
    <pf-button variant="success" :disabled="!newKey" @click="uploadKey">
      <save-icon /> {{ $t('Carica') }}
    </pf-button>

    <h3>{{ $t('Chiavi SSH associate al tuo account') }}</h3>
    <div v-if="!keys.length" class="blank-slate-pf">{{ $t('Nessuna chiave presente') }}</div>
    <ul v-for="k in keys" :key="k" class="row">
      <li>
        <key-icon />
        {{ k }}
        <pf-button small variant="link" @click="remove(k)">
          <trash-icon />
        </pf-button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { SSHKeysResource } from '@/resources';
import TrashIcon from '@vue-patternfly/icons/trash-icon';
import SaveIcon from '@vue-patternfly/icons/file-arrow-up-icon';
import KeyIcon from '@vue-patternfly/icons/key-icon';

const props = defineProps<{
  idPannello: number;
}>();

const loading = ref(false);
const keys = ref([] as string[]);
const newKey = ref('');

const loadkeys = async() => {
  loading.value = true;
  try {
    keys.value = await new SSHKeysResource({idPannello: props.idPannello}).get();
  } finally {
    loading.value = false;
  }
};

async function remove(k: string) {
  await new SSHKeysResource({idPannello: props.idPannello}).delete(encodeURIComponent(k));
  loadkeys();
}

async function uploadKey() {
  if (newKey.value && confirm('Vuoi caricare questa chiave SSH sul pannello?')) {
    const data = new FormData();
    data.append('idPannello', String(props.idPannello));
    data.append('key', newKey.value);
    await new SSHKeysResource().save(data);
    newKey.value = '';
    loadkeys();

  }
}

onMounted(() => {
  loadkeys();
});
</script>
