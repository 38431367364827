// eslint-disable-next-line vue/prefer-import-from-vue
import type { LooseRequired } from "@vue/shared";
import axios, { type AxiosResponse, type Method } from "axios";
import type { MaybePromise } from '@common/typeutils';
import { http } from '@/http';
import { computed, defineComponent, type ExtractPropTypes, onBeforeUnmount, onMounted, onUpdated, type PropType, type Ref, ref, watch } from "vue";
import { encodeForSend, isPlainObject, recursiveObjectPromiseAll } from '@common/utils';
import { useEventListener } from "@vueuse/core";
import debounce from "lodash-es/debounce";

type ErrorMessage = {
  type: string;
  message: string;
};

function responseToError(response: AxiosResponse): ErrorMessage {
  const error = {
    type: response.status == 500 || response.status == 502 ? 'danger' : 'warning',
    message: response.data?.message,
  };

  if (!error.message) {
    if (response.status == 400) {
      error.message = 'Richiesta malformata';
    } else if (response.status == 401) {
      error.message = 'Sessione scaduta';
    } else if (response.status == 503) {
      error.message = 'Il server sembra essere in manutenzione. Riprovare più tardi.';
    } else {
      error.message = 'Si è verificato un errore inatteso';
    }
  }

  const result = Number(response.data?.result);
  if (!isNaN(result)) {
    error.type = result === 1 ? 'danger' : 'warning';
  }

  return error;
}

function getParentTabInForm(el: Element) {
  const panel = el.closest('form [role=tabpanel]');
  if (!panel) {
    return null;
  }

  const panels = panel.parentElement?.children ? [...panel.parentElement.children] : [];
  const num = panels.indexOf(panel);

  return panel.parentElement?.previousElementSibling?.querySelector(`:scope > :nth-child(${num + 1}) > [role=tab]`);
}

type FormInputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

function isFormElement(e: any): e is FormInputElement {
  return e instanceof HTMLInputElement || e instanceof HTMLSelectElement || e instanceof HTMLTextAreaElement;
}

function refreshErrorBadgesInTabs(form: HTMLFormElement) {
  const tabErrorMap = new WeakMap<Element, {
    elements: FormInputElement[],
    errors: number,
  }>();

  // conto gli errori per ogni tab (se) presente
  const tabs = [];
  for (const el of form.elements) {
    if (!isFormElement(el)) {
      continue;
    }

    let tab = getParentTabInForm(el);
    while (tab) {
      tabs.push(tab);
      const tabInfo = tabErrorMap.get(tab) ?? {
        elements: [],
        errors: 0,
      };

      if (!el.validity?.valid) {
        tabInfo.elements.push(el);
        tabInfo.errors++;
      }

      tabErrorMap.set(tab, tabInfo);
      tab = getParentTabInForm(tab);
    }
  }

  // aggiungo/rimuovo/aggiorno i badge all'interno delle tab manipolando manualmente il dom
  for (const tab of tabs) {
    const tabInfo = tabErrorMap.get(tab);
    if (!tabInfo) {
      continue;
    }

    let badge = tab.querySelector(':scope > .badge-danger');
    if (tabInfo.errors) {
      if (!badge) {
        badge = document.createElement('span');
        badge.classList.add('badge');
        badge.classList.add('badge-danger');
        tab.appendChild(badge);
      }
      badge.innerHTML = tabInfo.errors.toString();
      badge.setAttribute('title', `Contiene ${tabInfo.errors} errori`);
    } else if (badge) {
      tab.removeChild(badge);
    }
  }
}

export function useXForm(
  props: Readonly<LooseRequired<Readonly<ExtractPropTypes<{disabled: boolean}>>>>,
) {
  const sending = ref(false);
  const form: Ref<HTMLFormElement | HTMLDivElement | null> = ref(null);
  const fieldset: Ref<HTMLFieldSetElement | null> = ref(null);

  const effectiveDisabled = computed(() => props.disabled || sending.value);

  let disabledMap: WeakMap<FormInputElement, boolean> | null = null;

  watch([effectiveDisabled], () => {
    const elements = () => [...fieldset.value?.elements ?? []].filter(isFormElement);

    if (effectiveDisabled.value) {
      disabledMap = new WeakMap();
      for (const input of elements()) {
        if (typeof input.disabled !== 'undefined' && !input.disabled) {
          disabledMap.set(input, input.disabled);
          input.disabled = true;
        }
      }
    } else if (disabledMap) {
      for (const input of elements()) {
        if (disabledMap.has(input)) {
          input.disabled = disabledMap.get(input) ?? false;
        }
      }
      disabledMap = null;
    }
  });

  const formObserver = new MutationObserver(debounce((mutationList: MutationRecord[]) => {
    for (const mut of mutationList) {
      if ([...mut.addedNodes, ...mut.removedNodes].some(el => el instanceof HTMLElement && (isFormElement(el) || el.querySelectorAll('input, select, textarea').length > 0))) {
        checkValidity();
      }
    }
  }, 500));

  const valid = ref(false);

  const checkValidity = () => {
    const formContainer: HTMLFormElement | HTMLFieldSetElement | null = form.value instanceof HTMLFormElement ? form.value : fieldset.value;
    if (formContainer) {
      valid.value = [...formContainer.elements].every(e => isFormElement(e) ? e.checkValidity() : true);
    } else {
      valid.value = false;
    }
    return valid.value;
  };

  const inputSet = new WeakSet<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>();

  const watchEdits = () => {
    for (const input of fieldset.value?.querySelectorAll('input, select, textarea') ?? []) {

      if (!(input instanceof HTMLInputElement || input instanceof HTMLSelectElement || input instanceof HTMLTextAreaElement)) {
        continue;
      }

      if (!inputSet.has(input)) {
        if (!(input instanceof HTMLInputElement) || !['checkbox', 'radio'].includes(input.type)) {
          useEventListener(input, 'input', checkValidity);
        }
        useEventListener(input, 'change', checkValidity);
        input.checkValidity();
        inputSet.add(input);
      }
    }
    checkValidity();
  };

  onUpdated(debounce(watchEdits, 500));

  onMounted(() => {
    watchEdits();
    if (form.value) {
      formObserver.observe(form.value, { childList: true, subtree: true });
    }
    if (fieldset.value?.checkValidity === HTMLFieldSetElement.prototype.checkValidity) {
      fieldset.value.checkValidity = checkValidity;
    }
    if (form.value instanceof HTMLFormElement && form.value?.checkValidity === HTMLFormElement.prototype.checkValidity) {
      form.value.checkValidity = checkValidity;
    }
  });

  onBeforeUnmount(() => {
    formObserver.disconnect();
    if (fieldset.value) {
      fieldset.value.checkValidity = HTMLFieldSetElement.prototype.checkValidity;
    }
    if (form.value instanceof HTMLFormElement) {
      form.value.checkValidity = HTMLFormElement.prototype.checkValidity;
    }
  });

  const submitErrors: Ref<ErrorMessage[]> = ref([]);

  return {
    form,
    fieldset,
    sending,
    effectiveDisabled,
    submitErrors,
    valid,
  };
}

export const XFormProps = {
  disabled: Boolean,
  xhr: Boolean,
  preventSubmit: Boolean,
  fake: Boolean,

  confirmMessage: {
    type: String,
    default: null,
  },

  action: {
    type: String,
    default: null,
  },

  method: {
    type: String as PropType<Method>,
    default: 'post',
  },

  encoding: {
    type: String as PropType<'form' | 'json'>,
    default: 'form',
    validator: (t: string) => ['form', 'json'].includes(t),
  },

  additionalData: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },

  onBeforeSubmit: Function as PropType<() => MaybePromise<boolean | undefined | void>>,
  onSending: Function as PropType<(v: boolean) => MaybePromise<void>>,
};

export const XFormMixin = defineComponent({
  props: XFormProps,

  emits: {
    submit: (e: Event | undefined, responseData: any) => true,
  },

  setup(props) {
    return {
      ...useXForm(props),
    };
  },

  methods: {
    async encode() {
      if (!this.fieldset) {
        return;
      }

      let data;
      if (this.form instanceof HTMLFormElement) {
        data = new FormData(this.form);
      } else {
        data = new FormData();
        for (const el of this.fieldset.elements) {
          if ((el instanceof HTMLInputElement || el instanceof HTMLSelectElement || el instanceof HTMLTextAreaElement) && el.name) {
            data.append(el.name, el.value);
          }
        }
      }

      if (this.encoding == 'form') {
        for (const [k, v] of Object.entries(this.additionalData)) {
          if (v && typeof v[Symbol.iterator] === 'function') {
            const name = `${k}[]`;
            for (const item of v) {
              if (typeof item === 'object' && !(item instanceof Blob)) {
                data.append(name, JSON.stringify(item));
              } else {
                data.append(name, item as any);
              }
            }
          } else {
            data.append(k, v);
          }
        }
        return data;
      }

      let jsonData: Record<string, any> = {};
      for (const [k, v] of data) {
        let encV: any = v;
        const inputElement = this.fieldset.elements.namedItem(k);
        if (inputElement instanceof HTMLInputElement && inputElement.type === 'checkbox') {
          encV = Boolean(encV);
        }
        encV = encodeForSend(encV);

        if (k.substring(k.length - 2) == '[]') {
          const arrK = k.substring(0, k.length - 2);
          if (!Object.hasOwnProperty.call(jsonData, arrK)) {
            jsonData[arrK] = [];
          }
          jsonData[arrK].push(encV);
        } else {
          jsonData[k] = encV;
        }
      }

      for (const [k, v] of Object.entries(this.additionalData)) {
        if (v && typeof v[Symbol.iterator] === 'function') {
          jsonData[k] = [];
          for (const item of v) {
            if (typeof item === 'object' && !isPlainObject(item)) {
              jsonData[k].push(encodeForSend(item));
            } else {
              jsonData[k].push(item);
            }
          }
        } else if (typeof v === 'object' && !isPlainObject(v)) {
          jsonData[k] = encodeForSend(v);
        } else {
          jsonData[k] = v;
        }
      }

      jsonData = await recursiveObjectPromiseAll(jsonData);
      return JSON.stringify(jsonData);
    },

    postSubmit(e: Event | undefined, responseData: unknown = undefined) {
      this.$emit('submit', e, responseData);
    },

    async submit(e: Event | undefined) {
      const prevent = e instanceof Event ? () => e.preventDefault() : () => { return; };

      if (this.sending || this.preventSubmit || (this.confirmMessage && !confirm(this.confirmMessage))) {
        prevent();
        return false;
      }

      if (!(this.form instanceof HTMLFormElement)) {
        return;
      }

      if (!this.form.reportValidity()) {
        prevent();
        refreshErrorBadgesInTabs(this.form);
        return false;
      }

      if (await this.onBeforeSubmit?.() === false) {
        prevent();
        return false;
      }

      if (!this.action) {
        prevent();
        this.postSubmit(e);
        return false;
      }

      if (!this.xhr) {
        return true;
      }

      prevent();

      // important! form data needs to be read before setting sending=true which in turn disables the form, making FormData empty.
      const data = await this.encode();

      this.sending = true;
      if (this.onSending) {
        await this.onSending(this.sending);
      }

      try {
        const headers: Record<string, string> = {};
        if (this.encoding == 'json') {
          headers['Content-Type'] = 'application/json';
        }
        const response = await http.request({
          method: this.method,
          url: this.action,
          headers,
          data,
        });

        if (Number(response.data?.result) >= 1) {
          const error = responseToError(response);
          if (error.message) {
            this.submitErrors.push(error);
          }
          return;
        }

        this.postSubmit(e, response.data);
      } catch (ex: any) {
        if (!axios.isAxiosError(ex) || !ex.response) {
          throw ex;
        }
        const error = responseToError(ex.response);
        if (error.message) {
          this.submitErrors.push(error);
        }
      } finally {
        this.sending = false;
        if (this.onSending) {
          await this.onSending(this.sending);
        }
      }

      return true;
    },

    validate() {
      if (!this.valid) {
        this.fieldset?.reportValidity();
      }
    },
  },
});
