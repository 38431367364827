<template>
  <div v-bind="(ouiaProps as any)" ref="tabPanel" class="tab-pane" :class="{ fade: transition > 0 }" role="tabpanel">
    <slot />
    <teleport v-if="isMounted && $slots.title" :to="'#' + uid.toString()">
      <slot name="title" />
    </teleport>
  </div>
</template>

<script lang="ts">
import { useChildrenTracker } from "@common/use";
import { defineComponent, inject, type PropType, type Ref, ref } from "vue";
import { TabsOnChangeInjectionKey, TabsInjectionKey } from "./PfTabs.vue";
import { ouiaProps, useOUIAProps } from "@common/ouia";

let id = 0;

export default defineComponent({
  name: 'PfTab',

  props: {
    title: {
      type: String,
      default: 'Tab Title',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabClasses: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    group: {
      type: String,
      default: undefined,
    },
    pullRight: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    ...ouiaProps,
  },

  emits: {
    active: (v: boolean) => v !== undefined,
  },

  setup(props) {
    const safe = ref(false);
    useChildrenTracker(TabsInjectionKey);
    return {
      safe,
      ouiaProps: useOUIAProps(props, {safe}),
      tabPanel: ref(null) as Ref<HTMLDivElement | null>,
      onChange: inject(TabsOnChangeInjectionKey),
    };
  },

  data(this: void) {
    return {
      active: false,
      transition: 150,
      uid: `tab_${++id}`,
      isMounted: false,
    };
  },

  watch: {
    active(active: boolean) {
      this.safe = false;
      this.$emit('active', active);
      if (!this.tabPanel) {
        return;
      }

      if (active) {
        setTimeout(() => {
          this.tabPanel?.classList.add('active');
          this.tabPanel?.offsetHeight;
          this.tabPanel?.classList.add('in');
          this.safe = true;
          this.onChange?.();
        }, this.transition);
      } else {
        this.tabPanel.classList.remove('in');
        setTimeout(() => {
          this.tabPanel?.classList.remove('active');
          this.safe = true;
        }, this.transition);
      }
    },
  },

  mounted() {
    this.isMounted = true;
  },

  unmounted() {
    this.isMounted = false;
  },

  methods: {
    show() {
      this.$nextTick(() => {
        this.tabPanel?.classList.add('active');
        this.tabPanel?.classList.add('in');
      });
    },
  },
});
</script>
