<template>
  <div
    v-bind="ouiaProps"
    tabindex="0"
    class="btn-group btn-group-flip"
    :class="{ 'inline-with-text': !noInline }"
    @keyup="keyUp"
  >
    <template v-if="offValue !== false">
      <btn-radio
        :name="name"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="onClass"
        inactive-class="btn-default"
        :checked-value="onValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ onText }}
      </btn-radio>
      <btn-radio
        :name="name"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="offClass"
        inactive-class="btn-default"
        :checked-value="offValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ offText }}
      </btn-radio>
    </template>
    <template v-else>
      <btn-radio
        :name="name"
        input="checkbox"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="onClass"
        inactive-class="btn-default"
        :checked-value="onValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ onText }}
      </btn-radio>
      <btn-radio
        :name="name"
        input="none"
        :model-value="radioValue"
        :class="[`btn-${size}`]"
        :active-class="offClass"
        inactive-class="btn-default"
        :checked-value="offValue"
        :required="required"
        :disabled="disabled"
        @update:model-value="change($event)"
      >
        {{ offText }}
      </btn-radio>
    </template>
    <pf-spinner v-if="loading" :size="size" inline />
    <slot />
  </div>
</template>

<style scoped>
.btn-group .btn:last-of-type {
  margin-right: 0.5em;
}
</style>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import BtnRadio from './btn-radio.vue';

export default defineComponent({
  name: 'Toggle',

  components: {
    BtnRadio,
  },

  props: {
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Boolean, String, Number, Array] as PropType<boolean | string | number | (boolean | string | number)[] | undefined>,
      required: true,
    },
    required: Boolean,
    disabled: Boolean,
    loading: Boolean,
    onText: {
      type: String,
      default: 'ON',
    },
    onValue: {
      type: [Boolean, String, Number],
      default: '1',
    },
    onClass: {
      type: String,
      default: 'btn-success',
    },
    offText: {
      type: String,
      default: 'OFF',
    },
    offValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    offClass: {
      type: String,
      default: 'btn-danger',
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
      default: 'sm',
      validator: (val: string) => ['lg', 'md', 'sm', 'xl'].includes(val),
    },
    noInline: Boolean,
    ...ouiaProps,
  },

  emits: ['update:modelValue'],

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
    };
  },

  computed: {
    radioValue() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.onValue) ? this.onValue : this.offValue;
      }
      return this.modelValue;
    },
  },

  methods: {
    keyUp(e: KeyboardEvent) {
      console.log(e.key);
      if (e.key === ' ' || e.key === 'Enter') {
        this.toggle();
      } else if (e.key === 'ArrowLeft') {
        this.change(this.onValue);
      } else if (e.key === 'ArrowRight') {
        this.change(this.offValue);
      }
    },

    change(value: boolean | string | number | (boolean | string | number)[]) {
      if (Array.isArray(this.modelValue)) {
        const onIdx = this.modelValue.indexOf(this.onValue);
        if (value === this.onValue) {
          if (onIdx < 0) {
            this.$emit('update:modelValue', [...this.modelValue, this.onValue]);
          }
        } else if (onIdx >= 0) {
          value = [...this.modelValue];
          value.splice(onIdx, 1);
          this.$emit('update:modelValue', value);
        }
        return;
      }
      this.$emit('update:modelValue', value);
    },

    toggle() {
      if (this.modelValue === this.onValue) {
        this.change(this.offValue);
      } else {
        this.change(this.onValue);
      }
    },
  },
});
</script>
