<template>
  <form role="form" @submit.prevent="save">
    <template v-if="loading">
      <div class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />

        <slot name="loading">
          <h3 class="blank-slate-pf-main-action">
            {{ $t('Caricamento configurazione zona in corso...') }}
          </h3>
        </slot>
      </div>
    </template>

    <table v-else class="table dataTable table-striped">
      <thead>
        <tr>
          <th>{{ $t('Dominio') }}</th>
          <th class="column-small">TTL</th>
          <th class="column-small">
            {{ $t('Tipo') }}
          </th>
          <th class="column-small">
            {{ $t('Peso') }}
          </th>
          <th>Target</th>
          <th v-if="login.grants.developer" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, i) in records" :key="i" role="row">
          <td>
            <input v-model="record.domain" type="text" class="form-control" :placeholder="$t('Dominio')" :disabled="!login.grants.developer">
          </td>
          <td>
            <input
              v-model="record.ttl"
              type="number"
              class="form-control"
              placeholder="TTL"
              min="800"
              max="2147483647"
              :disabled="!login.grants.developer"
            >
          </td>
          <td>
            <select v-model="record.type" class="form-control" :disabled="!login.grants.developer">
              <option value="A">A</option>
              <option value="CAA">CAA</option>
              <option value="CNAME">CNAME</option>
              <option value="DNAME">DNAME</option>
              <option value="DS">DS</option>
              <option value="LOC">LOC</option>
              <option value="MX">MX</option>
              <option value="TXT">TXT</option>
              <option value="RP">RP</option>
              <option value="SPF">SPF</option>
              <option value="SRV">SRV</option>
              <option value="SSHPF">SSHPF</option>
            </select>
          </td>
          <td>
            <input
              v-if="record.type == 'MX'"
              v-model="record.weight"
              type="number"
              class="form-control"
              :placeholder="$t('Peso')"
              min="0"
              max="2147483647"
            >
          </td>
          <td>
            <x-input v-model="record.target" :type="targetType(record.domain, record.type)" placeholder="Target" :disabled="!login.grants.developer">
              <div
                v-if="dominio && ['CNAME', 'DNAME', 'MX'].includes(record.type ?? '') && !isFQ(record.target)"
                class="input-group-addon"
              >
                .{{ dominio }}
              </div>
            </x-input>
          </td>
          <td v-if="login.grants.developer" class="table-view-pf-actions">
            <div class="table-view-pf-btn">
              <button type="button" class="btn btn-danger" title="Elimina" @click="remove(i)">
                <trash-icon />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="login.grants.developer" class="form-footer">
      <div class="pull-right">
        <button type="button" class="btn btn-default" :disabled="!modified" @click="undo">
          {{ $t('Annulla') }}
        </button>
        &nbsp;
        <button type="submit" class="btn btn-primary" :disabled="!modified">
          <save-icon /> {{ $t('Salva') }}
        </button>
      </div>

      <button type="button" class="btn btn-success" :disabled="loading" @click="add">
        <circle-plus-icon /> {{ $t('Nuovo Record') }}
      </button>
      &nbsp;
      <pf-dropdown ref="defaultRecordsDropdown" :text="$t('Record di posta predefiniti')">
        <li v-if="dominio" @click="addOffice365Records">
          <a>{{ $t('Aggiungi record di Office 365') }}</a>
        </li>
        <li @click="addGoogleMxRecords">
          <a>{{ $t('Aggiungi record di GMail') }}</a>
        </li>
      </pf-dropdown>
    </div>
    <pf-notification v-else type="warning">
      {{ $t('Modifica dei record DNS non abilitata. Richiedi i permessi tecnici ad un utente amministratore per visualizzarle') }}
    </pf-notification>

    <div class="pull-right download-csv">
      <button type="button" class="btn btn-warning" :disabled="loading" @click="scarica">
        <download-icon /> {{ $t('Scarica la zona DNS') }}
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
table.dataTable {
  margin-bottom: 20px;
}
.column-small {
  width: 8em;
}

.form-footer {
  padding-top: 10px;
}

.download-csv{
  margin-top: 20px;
}
</style>

<script lang="ts">
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import { saveAs } from '@common/utils';
import XInput from './x-input.vue';
import { DnsResource, type DnsRecord } from '@/resources';
import { useLoginStore } from '@/store/login';
import { defineComponent, type Ref, ref } from 'vue';
import type { PfDropdown } from 'vue-patternfly';
import { isDefined } from '@vueuse/shared';
import { $t } from '@/i18n';
import DownloadIcon from '@vue-patternfly/icons/download-icon';
import CirclePlusIcon from '@vue-patternfly/icons/circle-plus-icon';
import SaveIcon from '@vue-patternfly/icons/save-alt-icon';
import TrashIcon from '@vue-patternfly/icons/trash-icon';

export default defineComponent({
  name: 'DnsRecords',

  components: {
    XInput,
    DownloadIcon,
    CirclePlusIcon,
    SaveIcon,
    TrashIcon,
  },

  props: {
    dominio: {
      type: String,
      required: true,
    },
  },

  setup() {
    const login = useLoginStore();
    const defaultRecordsDropdown: Ref<typeof PfDropdown | undefined> = ref();
    return {
      login,
      defaultRecordsDropdown,
    };
  },

  data() {
    return {
      loading: false,
      records: [] as Partial<DnsRecord>[],
      undoRecords: [] as Partial<DnsRecord>[],
    };
  },

  computed: {
    modified() {
      return !isEqual(this.records, this.undoRecords);
    },
  },

  watch: {
    dominio: {
      async handler() {
        this.loading = true;
        try {
          this.records = await new DnsResource().get(this.dominio);
          this.undoRecords = cloneDeep(this.records);
        } finally {
          this.loading = false;
        }
      },
      immediate: true,
    },
  },

  methods: {
    add() {
      this.records.push({
        type: 'A',
      });
    },

    remove(record: number) {
      this.records.splice(record, 1);
    },

    undo() {
      this.records = cloneDeep(this.undoRecords);
    },

    async save() {
      await new DnsResource().save(this.dominio, this.records);
      this.undoRecords = cloneDeep(this.records);
    },

    isDKIM(domain: string | null | undefined) {
      return domain?.substring(domain.length - 11) === '._domainkey';
    },

    isDMARC(domain: string | null | undefined) {
      return domain === '_dmarc' || domain?.startsWith('_dmarc.');
    },

    isFQ(target: string | null | undefined) {
      return target?.substring(target.length - 1) === '.';
    },

    targetType(domain: string | null | undefined, type: string | null | undefined) {
      if (type == 'TXT' && this.isDKIM(domain)) {
        return 'dkim';
      }
      if (type == 'TXT' && this.isDMARC(domain)) {
        return 'dmarc';
      }
      if (type == 'A') {
        return 'ipv4';
      }
      return 'text';
    },

    removeAllMxRecord() {
      for (let i = this.records.length - 1; i >= 0; --i) {
        if (this.records[i].type == 'MX') {
          this.remove(i);
        }
      }
    },

    findSpfRecord() {
      for (const record of this.records) {
        if (['SPF', 'TXT'].includes(record.type ?? '') && record.target?.startsWith('v=spf1 ')) {
          return record;
        }
      }
    },

    addGoogleMxRecords() {
      this.defaultRecordsDropdown?.toggle(false);

      if (!window.confirm($t('Attenzione! Eventuali MX già presenti verranno rimossi e sostituiti da quelli scelti. Vuoi procedere?'))) {
        return;
      }

      this.removeAllMxRecord();

      const spf = this.findSpfRecord();
      if (spf?.target) {
        if (!spf.target.includes(' include:_spf.google.com ')) {
          spf.target = spf.target.replace('v=spf1 ', 'v=spf1 include:_spf.google.com ');
        }
      } else {
        this.records.push({
          domain: '@',
          type: 'TXT',
          target: 'v=spf1 a mx include:spf.artera.net include:_spf.google.com -all',
        });
      }

      this.records.push({
        domain: '@',
        type: 'MX',
        weight: '1',
        target: 'ASPMX.L.GOOGLE.COM.',
      });
      this.records.push({
        domain: '@',
        type: 'MX',
        weight: '5',
        target: 'ALT1.ASPMX.L.GOOGLE.COM.',
      });
      this.records.push({
        domain: '@',
        type: 'MX',
        weight: '5',
        target: 'ALT2.ASPMX.L.GOOGLE.COM.',
      });
      this.records.push({
        domain: '@',
        type: 'MX',
        weight: '10',
        target: 'ALT3.ASPMX.L.GOOGLE.COM.',
      });
      this.records.push({
        domain: '@',
        type: 'MX',
        weight: '10',
        target: 'ALT4.ASPMX.L.GOOGLE.COM.',
      });
    },

    addOffice365Records() {
      this.defaultRecordsDropdown?.toggle(false);

      if (!this.dominio) {
        return;
      }

      if (!window.confirm($t('Attenzione! Eventuali MX già presenti verranno rimossi e sostituiti da quelli scelti. Vuoi procedere?'))) {
        return;
      }

      this.removeAllMxRecord();

      const spf = this.findSpfRecord();
      if (spf?.target) {
        if (!spf.target.includes(' include:spf.protection.outlook.com ')) {
          spf.target = spf.target.replace('v=spf1 ', 'v=spf1 include:spf.protection.outlook.com ');
        }
      } else {
        this.records.push({
          domain: '@',
          type: 'TXT',
          target: 'v=spf1 a mx include:spf.artera.net include:spf.protection.outlook.com -all',
        });
      }

      this.records.push({
        domain: '@',
        type: 'MX',
        weight: '0',
        target: `${this.dominio.replace(/\./g, '-')}.mail.protection.outlook.com.`,
      });
      this.records.push({
        domain: 'autodiscover',
        type: 'CNAME',
        ttl: '3600',
        target: 'autodiscover.outlook.com.',
      });
      this.records.push({
        domain: 'sip',
        type: 'CNAME',
        ttl: '3600',
        target: 'sipdir.online.lync.com.',
      });
      this.records.push({
        domain: 'lyncdiscover',
        type: 'CNAME',
        ttl: '3600',
        target: 'webdir.online.lync.com.',
      });
      this.records.push({
        domain: 'msoid',
        type: 'CNAME',
        ttl: '3600',
        target: 'clientconfig.microsoftonline-p.net.',
      });
      this.records.push({
        domain: 'enterpriseregistration',
        type: 'CNAME',
        ttl: '3600',
        target: 'enterpriseregistration.windows.net.',
      });
      this.records.push({
        domain: 'enterpriseenrollment',
        type: 'CNAME',
        ttl: '3600',
        target: 'enterpriseenrollment.manage.microsoft.com.',
      });
      this.records.push({
        domain: '_sip._tls',
        type: 'SRV',
        ttl: '3600',
        target: '100 1 443 sipdir.online.lync.com.',
      });
      this.records.push({
        domain: '_sipfederationtls._tcp',
        type: 'SRV',
        ttl: '3600',
        target: '100 1 5061 sipfed.online.lync.com.',
      });
    },

    scarica() {
      let csv = ['DOMINIO', 'TTL', 'TIPO', 'PESO', 'TARGET'];

      csv = [csv.join(';')];

      for (const row of this.records) {
        const csvrow = [];
        for (const f of ['domain', 'ttl', 'type', 'weight', 'target'] as const) {
          let value = row[f];

          if (!isDefined(value)) {
            csvrow.push('');
            continue;
          }

          // Target check
          if (f == 'target' && !this.isFQ(value) && (row.type == 'CNAME' || row.type == 'MX')) {
            value = `${value}.${this.dominio}.`;
          }

          const typ = typeof value;
          if (typ == 'string') {
            value = `"${value.split('"').join('""')}"`;
          } else if (typ == 'number') {
            value = parseFloat(value).toLocaleString();
          }

          csvrow.push(value);
        }
        csv.push(csvrow.join(';'));
      }

      const blob = new Blob([csv.join('\r\n')], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(blob, `${this.dominio.replace('.', '')}-dns.csv`);
    },
  },
});
</script>
