<template>
  <div v-bind="(ouiaProps as any)" class="list-group list-view-pf list-view-pf-view">
    <slot />
  </div>
</template>

<script lang="ts">
export interface Props extends OUIAProps, /* @vue-ignore */ HTMLAttributes {}
</script>

<script lang="ts" setup>
import type { HTMLAttributes } from "vue";
import { useOUIAProps, type OUIAProps } from "../ouia";

const props = defineProps<Props>();
const ouiaProps = useOUIAProps(props);

defineSlots<{
  default?: (props?: Record<never, never>) => any;
}>();
</script>
