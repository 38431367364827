<template>
  <div
    class="x-input"
    :class="[props.class, {
      'has-error': isInvalid,
    }]"
  >
    <div class="input-group col-sm-12">
      <label v-if="hasPreAddon" class="input-group-addon" @click="input?.focus()">
        <input v-if="withCheckbox" v-model="effectiveChecked" type="checkbox" :name="withCheckbox === true ? undefined : withCheckbox" value="1">
        <slot name="prepend">{{ prepend }}</slot>
      </label>

      <input
        ref="input"
        v-bind="$attrs"
        :value="inputValue"
        :type="inputType"
        :pattern="pattern ? pattern : typePattern"
        :title="title ? title : typeTitle"
        class="form-control"
        :class="{
          'validation-failed': isInvalid,
        }"
        :disabled="effectiveDisabled"
        @change="reportValidity"
        @invalid="isInvalid = true"
        @input="change"
      >

      <label v-if="hasPostAddon" class="input-group-addon" @click="input?.focus()">
        <slot name="append">
          {{ append }}
          <pf3-icon v-if="icon" :name="icon" />
        </slot>
      </label>

      <label v-if="resetValue !== undefined" class="input-group-btn">
        <pf-tooltip>
          <template #content>Reset</template>
          <button
            :disabled="effectiveDisabled || resetValue === modelValue"
            type="button"
            class="btn btn-default"
            @click="reset"
          >
            <rotate-left-icon />
          </button>
        </pf-tooltip>
      </label>

      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.x-input {
  width: 100%;
}
</style>

<script lang="ts">
export interface Props extends XFormElementProps, /* @vue-ignore */ Omit<InputHTMLAttributes, 'checked' | 'disabled' | 'type'> {
  help?: string;
  nativeErrors?: boolean;
}
</script>

<script lang="ts" setup>
import { useXFormElement, type Props as XFormElementProps, type Emits as XFormElementEmits } from '@common/mixins/XFormElement';
import type { InputHTMLAttributes } from 'vue';
import { $t } from '../i18n';
import RotateLeftIcon from '@vue-patternfly/icons/rotate-left-icon';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  checked: undefined,
});

const emit = defineEmits<XFormElementEmits>();

const {
  change,
  effectiveChecked,
  effectiveDisabled,
  hasPostAddon,
  hasPreAddon,
  input,
  inputType,
  inputValue,
  isInvalid,
  checkValidity,
  reportValidity,
  reset,
  typePattern,
  typeTitle,
} = useXFormElement(props, emit, $t);

defineExpose({
  input,
  checkValidity,
  reportValidity,
  reset,
});
</script>
