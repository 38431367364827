<template>
  <component :is="iconMap[name]" data-ouia-component-type="V-PF3/Icon" :class="name" />
</template>

<script lang="ts">
// $> grep -Eorh '<pf3-icon[^>]+name="[^"]+"' src | sed -r 's/^.*name="|"$//g' | sort | uniq -c

export const iconMap = {
  'fa-angle-double-left': ChevronLeftIcon,
  'fa-angle-double-right': ChevronRightIcon,
  'fa-archive': BoxArchiveIcon,
  'fa-arrow-circle-o-down': CircleDownIcon,
  'fa-ban': BanIcon,
  'fa-caret-right': CaretRightIcon,
  'fa-check': CheckIcon,
  'fa-chevron-down': ChevronDownIcon,
  'fa-clock-o': ClockIcon,
  'fa-close': XmarkIcon,
  'fa-cloud-upload': CloudArrowUpIcon,
  'fa-cogs': GearsIcon,
  'fa-comments': CommentsIcon,
  'fa-compress': DownLeftAndUpRightToCenterIcon,
  'fa-copy': CopyIcon,
  'fa-download': DownloadIcon,
  'fa-envelope-o': EnvelopeIcon,
  'fa-envelope': EnvelopeIcon,
  'fa-exclamation': ExclamationIcon,
  'fa-external-link': ShareFromSquareIcon,
  'fa-eye-slash': EyeSlashIcon,
  'fa-eye': EyeIcon,
  'fa-forward': ForwardIcon,
  'fa-key': KeyIcon,
  'fa-legal': GavelIcon,
  'fa-list': ListIcon,
  'fa-lock': LockIcon,
  'fa-minus-square-o': SquareMinusIcon,
  'fa-money': MoneyBillIcon,
  'fa-pause': PauseIcon,
  'fa-phone': PhoneIcon,
  'fa-play': PlayIcon,
  'fa-plus-square-o': SquarePlusIcon,
  'fa-plus': PlusIcon,
  'fa-print': PrintIcon,
  'fa-refresh': ArrowsRotateIcon,
  'fa-repeat': RotateRightIcon,
  'fa-server': ServerIcon,
  'fa-shopping-cart': CartShoppingIcon,
  'fa-spinner': SpinnerIcon,
  'fa-table': TableIcon,
  'fa-tag': TagIcon,
  'fa-ticket': TicketIcon,
  'fa-times': XmarkIcon,
  'fa-trash': TrashIcon,
  'fa-undo': RotateLeftIcon,
  'fa-unlock': LockOpenIcon,
  'fa-user': UserIcon,
  'fa-warning': TriangleExclamationIcon,
  'glyphicon-remove': XmarkIcon,
  'pficon-add-circle-o': CirclePlusIcon,
  'pficon-arrow': ShareFromSquareIcon,
  'pficon-close': XmarkIcon,
  'pficon-cpu': MicrochipIcon,
  'pficon-delete': TrashIcon,
  'pficon-edit': PenIcon,
  'pficon-enterprise': IndustryIcon,
  'pficon-error-circle-o': CircleExclamationIcon,
  'pficon-filter': FilterIcon,
  'pficon-history': RotateLeftIcon,
  'pficon-info': CircleInfoIcon,
  'pficon-import': FileImportIcon,
  'pficon-key': KeyIcon,
  'pficon-memory': MemoryIcon,
  'pficon-ok': CheckIcon,
  'pficon-private': LockIcon,
  'pficon-refresh': ArrowsRotateIcon,
  'pficon-save': DownloadIcon,
  'pficon-search': MagnifyingGlassIcon,
  'pficon-settings': SlidersIcon,
  'pficon-user': UserIcon,
  'pficon-virtual-machine': DesktopIcon,
  'pficon-volume': HardDriveIcon,
  'pficon-warning-triangle-o': TriangleExclamationIcon,
};
</script>

<script lang="ts" setup>
import ArrowsRotateIcon from '@vue-patternfly/icons/arrows-rotate-icon';
import BanIcon from '@vue-patternfly/icons/ban-icon';
import BoxArchiveIcon from '@vue-patternfly/icons/box-archive-icon';
import CaretRightIcon from '@vue-patternfly/icons/caret-right-icon';
import CartShoppingIcon from '@vue-patternfly/icons/cart-shopping-icon';
import CheckIcon from '@vue-patternfly/icons/check-icon';
import ChevronDownIcon from '@vue-patternfly/icons/chevron-down-icon';
import ChevronLeftIcon from '@vue-patternfly/icons/chevron-left-icon';
import ChevronRightIcon from '@vue-patternfly/icons/chevron-right-icon';
import CircleDownIcon from '@vue-patternfly/icons/circle-down-icon';
import CircleInfoIcon from '@vue-patternfly/icons/circle-info-icon';
import CircleExclamationIcon from '@vue-patternfly/icons/circle-exclamation-icon';
import CirclePlusIcon from '@vue-patternfly/icons/circle-plus-icon';
import ClockIcon from '@vue-patternfly/icons/clock-icon';
import CloudArrowUpIcon from '@vue-patternfly/icons/cloud-arrow-up-icon';
import CommentsIcon from '@vue-patternfly/icons/comments-icon';
import CopyIcon from '@vue-patternfly/icons/copy-icon';
import DesktopIcon from '@vue-patternfly/icons/desktop-icon';
import DownLeftAndUpRightToCenterIcon from '@vue-patternfly/icons/down-left-and-up-right-to-center-icon';
import DownloadIcon from '@vue-patternfly/icons/download-icon';
import EnvelopeIcon from '@vue-patternfly/icons/envelope-icon';
import ExclamationIcon from '@vue-patternfly/icons/exclamation-icon';
import EyeIcon from '@vue-patternfly/icons/eye-icon';
import EyeSlashIcon from '@vue-patternfly/icons/eye-slash-icon';
import FileImportIcon from '@vue-patternfly/icons/file-import-icon';
import FilterIcon from '@vue-patternfly/icons/filter-icon';
import ForwardIcon from '@vue-patternfly/icons/forward-icon';
import GearsIcon from '@vue-patternfly/icons/gears-icon';
import HardDriveIcon from '@vue-patternfly/icons/hard-drive-icon';
import IndustryIcon from '@vue-patternfly/icons/industry-icon';
import KeyIcon from '@vue-patternfly/icons/key-icon';
import GavelIcon from '@vue-patternfly/icons/gavel-icon';
import ListIcon from '@vue-patternfly/icons/list-icon';
import LockIcon from '@vue-patternfly/icons/lock-icon';
import LockOpenIcon from '@vue-patternfly/icons/lock-open-icon';
import MagnifyingGlassIcon from '@vue-patternfly/icons/magnifying-glass-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import MicrochipIcon from '@vue-patternfly/icons/microchip-icon';
import MoneyBillIcon from '@vue-patternfly/icons/money-bill-icon';
import PauseIcon from '@vue-patternfly/icons/pause-icon';
import PenIcon from '@vue-patternfly/icons/pen-icon';
import PlayIcon from '@vue-patternfly/icons/play-icon';
import PhoneIcon from '@vue-patternfly/icons/phone-icon';
import PlusIcon from '@vue-patternfly/icons/plus-icon';
import PrintIcon from '@vue-patternfly/icons/print-icon';
import RotateLeftIcon from '@vue-patternfly/icons/rotate-left-icon';
import RotateRightIcon from '@vue-patternfly/icons/rotate-right-icon';
import ServerIcon from '@vue-patternfly/icons/server-icon';
import ShareFromSquareIcon from '@vue-patternfly/icons/share-from-square-icon';
import SlidersIcon from '@vue-patternfly/icons/sliders-icon';
import SpinnerIcon from '@vue-patternfly/icons/spinner-icon';
import SquareMinusIcon from '@vue-patternfly/icons/square-minus-icon';
import SquarePlusIcon from '@vue-patternfly/icons/square-plus-icon';
import TableIcon from '@vue-patternfly/icons/table-icon';
import TagIcon from '@vue-patternfly/icons/tag-icon';
import TicketIcon from '@vue-patternfly/icons/ticket-icon';
import TrashIcon from '@vue-patternfly/icons/trash-icon';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';
import UserIcon from '@vue-patternfly/icons/user-icon';
import XmarkIcon from '@vue-patternfly/icons/xmark-icon';

defineProps<{
  name: keyof typeof iconMap;
}>();
</script>
