<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Scopo del progetto')">
    <p v-if="wizard.searchedDomain && wizard.availableDomain !== null">
      {{ $t('Il dominio {0} risulta: ', [wizard.nomeDominio]) }}
      <span v-if="wizard.availableDomain"><strong class="text-success">{{ $t("Disponibile") }}</strong>. {{ $t('Ci occuperemo noi delle pratiche di registrazione.') }}</span>
      <span v-else><strong class="text-warning">{{ $t("Già registrato") }}</strong>. {{ $t('Ci occorrerà solo il codice auth-code e poi ci occuperemo noi di tutte le pratiche di trasferimento.') }}</span>
    </p>

    <div class="question-wrapper">
      <h3 v-if="wizard.searchedDomain">{{ $t('Quali servizi vuoi associare al dominio scelto: {0}', [wizard.nomeDominio]) }}</h3>
      <template v-else>
        <h3>{{ $t('Quale tipologia di servizio ti occorre?') }}</h3>
        <p>
          {{ $t('Come prima cosa ti chiediamo di indicarci qual è la tipologia di servizio che stai cercando.') }}
        </p>
      </template>
      <wizard-question-path v-model="wizard.path" name="path" required />
    </div>
  </wizard-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';
import WizardQuestionPath from '../questions/wizard-question-path.vue';
import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepScopo',

  components: {
    WizardQuestionPath,
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep(),
    };
  },
});
</script>
