import { defineStore } from 'pinia';
import { useLoginStore } from './login';
import { CompanyResource } from '@/resources';

export interface CompanyData {
  id: number | string | null,
  ragione_sociale: string | null,
}

export const useCompanyStore = defineStore('company', {
  state: (): CompanyData => ({
    id: null,
    ragione_sociale: null,
  }),

  getters: {
    name(state) {
      const login = useLoginStore();
      return state.id ? state.ragione_sociale : login.azienda;
    },

    code(state) {
      const login = useLoginStore();
      return state.id ? state.id : login.id_azienda;
    },
  },

  actions: {
    async fetch(id: number | string) {
      try {
        const company = await new CompanyResource().get(id);
        this.id = company.id;
        this.ragione_sociale = company.ragione_sociale;
      } catch (ex) {
        this.$reset();
        throw ex;
      }
    },

    async refresh() {
      if (this.id) {
        await this.fetch(this.id);
      }
    },
  },
});
