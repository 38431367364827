<template>
  <ul class="radio-wrapper">
    <li class="radio-description" style="width: 100%">
      <label>
        <input
          type="radio"
          :checked="modelValue.length === 0"
          @change="reset"
        >
        {{ _t('Nessuno') }}
      </label>
    </li>
    <li v-for="opt of wizard.opzioniHardening" :key="opt" class="radio-description">
      <label>
        <input
          type="checkbox"
          name="hardening[]"
          :value="opt"
          :checked="modelValue.includes(opt)"
          @change="change"
        >
        {{ opt }}
      </label>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { $t as _t } from '@/i18n';
import { useWizardQuestion } from '../composition';

defineProps<{
  modelValue: (typeof wizard.opzioniHardening[number])[];
}>();

const { wizard } = useWizardQuestion();

function change(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    if (e.target.checked) {
      wizard.hardening.push(e.target.value as typeof wizard.opzioniHardening[number]);
    } else {
      wizard.hardening.splice(wizard.hardening.indexOf(e.target.value as typeof wizard.opzioniHardening[number]), 1);
    }
  }
}

function reset(e: Event) {
  wizard.hardening = [];
}
</script>
