<template>
  <page
    v-model:filter="q"
    :name="$t('My cloud')"
    :empty="servers.length <= 0"
    :state="serversState"
    with-filter
  >
    <pf-list-view v-slot="{ row }" :rows="(filtered as any[])" :items-per-page="0" key-name="nome" stacked>
      <server-detail :server="row" />
    </pf-list-view>
  </page>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ServerResource } from '@/resources';
import { asyncComputedList } from '@common/asyncComputed';
import { any } from '@common/utils';

import Page from '../components/page.vue';
import ServerDetail from '../components/server-detail.vue';

const { state: serversState, result: servers } = asyncComputedList(() => new ServerResource().get());

const q = ref('');
const filtered = computed(() => servers.value.filter(any(q.value)));
</script>
