<template>
  <page :name="$t('Sblocco IP')">
    <p>{{ $t(
      'I nostri sistemi di sicurezza sono in grado di bloccare in modo automatico molti tipi di attacchi diretti ai nostri server. In alcuni casi si può subire un blocco temporaneo degli accessi a causa di richieste che possono essere scambiate per uno di questi attacchi, come ad esempio quando si inserisce la password errata per troppe volte consecutive.',
    ) }}</p>

    <p>{{ $t(
      'Se pensi di essere stato bloccato per errore puoi provare a richiedere lo sblocco del tuo IP da questo pannello. Il tuo IP verrà rilevato in modo automatico e precompilato ma puoi cambiarlo se stai accedendo da un altro sistema.',
    ) }}</p>

    <p>
      <i18n-t
        keypath="Se la richiesta non sortisce alcun effetto ti invitiamo ad {0} e i nostri tecnici prenderanno in carico la questione."
        tag="span"
      >
        <router-link :to="{ name: 'tickets' }">
          {{ $t('aprire un ticket') }}
        </router-link>
      </i18n-t>
    </p>
    <div class="row">
      <form class="col-md-6 col-lg-4" role="form" @submit.prevent="unban">
        <div class="form-group">
          <label for="unbanIP">{{ $t('Indirizzo IP') }}</label>
          <input
            id="unbanIP"
            v-model="ip"
            :disabled="disabled || undefined"
            type="text"
            class="form-control"
            :placeholder="$t('Indirizzo IP')"
            required
          >
        </div>

        <button :disabled="disabled || undefined" type="submit" class="btn btn-primary">
          {{ $t('Sblocca') }}
        </button>
      </form>
    </div>
  </page>
</template>

<script lang="ts">
import { http } from '../http';

import Page from '../components/page.vue';
import { useAppStore } from '@/store/app';
import { defineComponent } from 'vue';
import { Resource } from '@/resources';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'SecurityPage',

  components: {
    Page,
  },

  data() {
    return {
      disabled: false,
      ip: '',
    };
  },

  async mounted() {
    this.ip = (await http.get('/ip')).data;
  },

  methods: {
    async unban() {
      if (this.disabled) {
        return;
      }

      const app = useAppStore();
      this.disabled = true;

      try {
        const ban = new Resource('ip-ban');
        const response = await ban.delete(this.ip);
        if (response) {
          app.toast($t('Le corrispondenze trovate sono state sbloccate'), 'success');
        } else {
          app.toast($t("Non sono state trovate corrispondenze con l'indirizzo specificato"), 'warning');
        }
      } finally {
        this.disabled = false;
      }
    },
  },
});
</script>
