<template>
  <login-layout>
    <header class="login-pf-header">
      <h1>{{ $t('Concedi accesso alla tua area riservata.') }}</h1>
    </header>

    <pf-spinner v-if="asyncComputed.authrequest.updating" />

    <template v-else-if="authrequest.username">
      <p>
        <i18n-t keypath="Abilitare l'utenza {user} ad accedere all'area riservata di {company} come utente di tipo amministratore?" tag="p">
          <template #user>
            <strong>
              {{ authrequest.username }}
              <template v-if="authrequest.username != authrequest.email">- &lt;{{ authrequest.email }}&gt;</template>
            </strong>
          </template>
          <template #company>
            <strong>{{ authrequest.azienda }}</strong>
          </template>
        </i18n-t>
      </p>
      <p>
        {{
          $t(
            'Ti ricordiamo che potrai modificare o revocare i permessi accordati in qualsiasi momento accedendo alla sezione "Utenze".',
          )
        }}
      </p>

      <button type="button" class="btn btn-primary btn-block btn-lg" :disabled="sending" @click="submit">
        {{ $t('Autorizza Accesso') }}
        <div v-if="sending" class="spinner spinner-xs" />
      </button>
    </template>

    <p v-else>{{ $t('La richiesta di accesso non è valida o è scaduta.') }}</p>

    <button type="button" class="btn btn-default btn-block btn-lg btn-logout" @click="next">{{ $t('Ignora') }}</button>
  </login-layout>
</template>

<style lang="scss" scoped>
.btn-logout {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { http } from '../../http';
import { setupAsyncComputed } from '@common/asyncComputed';
import { loginDestination } from '../../utils';
import LoginLayout from '../../components/login-layout.vue';
import { defineComponent } from 'vue';
import { useLoginStore } from '@/store/login';

interface AuthRequestInfo {
  azienda?: string;
  username?: string;
  email?: string;
}

export default defineComponent({
  name: 'AuthorizeUserPage',

  components: {
    LoginLayout,
  },

  props: {
    aid: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    tok: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      ...setupAsyncComputed({
        authrequest: {
          async get(this: any): Promise<AuthRequestInfo> {
            if (!this.aid || !this.uid || !this.tok) {
              return {};
            }
            return (await http.post('/rest/login/authrequestinfo', {
              aid: this.aid,
              uid: this.uid,
              tok: this.tok,
            })).data;
          },
          default: {} as AuthRequestInfo,
        },
      }),
    };
  },

  data() {
    return {
      sending: false,
    };
  },

  methods: {
    async submit() {
      this.sending = true;

      try {
        const data = new FormData();
        data.append('id_azienda', this.aid);
        data.append('uid', this.uid);
        data.append('tok', this.tok);

        const result = (await http.post('/api/auth/companylogin', data)).data.result;

        if (result == 'ok') {
          const login = useLoginStore();
          await login.refresh();
          this.next();
        }
      } finally {
        this.sending = false;
      }
    },

    next() {
      this.$router.push(loginDestination(this.$route.query));
    },
  },
});
</script>
