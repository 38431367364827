<template>
  <page
    :name="$t('Documenti utili')"
    :description="$t('Qui puoi trovare tutti i documenti e moduli utili')"
    :empty="!billing.length && !contracts.length && !other.length"
    :empty-text="$t('Nessun documento disponibile')"
  >
    <document-list v-if="contracts.length" :title="$t('Condizioni Contrattuali')" :documents="contracts" :label="$t('Visualizza')" />

    <document-list v-if="billing.length" :title="$t('Modulistica amministrativa')" :documents="billing" />

    <document-list v-if="other.length" :title="$t('Altri moduli')" :documents="other" />
  </page>
</template>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .list-view-pf-description {
    width: 100%;
  }
}
</style>

<script lang="ts">
import { $t } from '@/i18n';
import { useLoginStore } from '@/store/login';
import { defineComponent } from 'vue';
import Page from '../components/page.vue';
import DocumentList, { type Document } from '../components/document-list.vue';

type MultiLanguageDocument = {
  name: string;
  description: string;
  links: {
    it: string;
    en: string;
    fr: string;
    de: string;
  },
}

export default defineComponent({
  name: 'DocumentsPage',

  components: {
    Page,
    DocumentList,
  },

  setup() {
    return {
      login: useLoginStore(),
    };
  },

  data() {
    return {
      docBilling: [
        {
          BBW: {
            name: $t('Cambio azienda di fatturazione di un servizio'),
            description: $t(
              "Documento per gestire il cambio di fatturazione dei servizi. Una volta compilato in tutte le sue parti e firmato da entrambe le aziende coinvolte è da inviare a support{'@'}artera.net",
            ),
            links: {
              it: 'https://files.artera.net/s/WFBREYMaT8ZbEMR',
              en: 'https://files.artera.net/s/WFBREYMaT8ZbEMR',
              fr: 'https://files.artera.net/s/WFBREYMaT8ZbEMR',
              de: 'https://files.artera.net/s/WFBREYMaT8ZbEMR',
            },
          },
          SEE: {
            name: $t('FACSIMILE - Cambio azienda di fatturazione di un servizio'),
            description: $t(
              "Facsimile del documento di cambio fatturazione dei servizi. Ci occorre ricevere alla mail support{'@'}artera.net questo documento su carta intestata dell'azienda attualmente intestataria dei servizi e firmato dai due rappresentati legali delle aziende coinvolte",
            ),
            links: {
              it: 'https://files.artera.net/s/okZGrJpEinrRg2P',
              en: 'https://files.artera.net/s/okZGrJpEinrRg2P',
              fr: 'https://files.artera.net/s/okZGrJpEinrRg2P',
              de: 'https://files.artera.net/s/okZGrJpEinrRg2P',
            },
          },
        },
        {
          BBW: {
            name: $t('Modifica del registrante di un dominio'),
            description: $t(
              "Documento per gestire il cambio di registrante di un dominio. Una volta compilato in tutte le sue parti e firmato è da inviare a support{'@'}artera.net",
            ),
            links: {
              it: 'https://files.artera.net/s/dysorb5n3ywKo5L',
              en: 'https://files.artera.net/s/dysorb5n3ywKo5L',
              fr: 'https://files.artera.net/s/dysorb5n3ywKo5L',
              de: 'https://files.artera.net/s/dysorb5n3ywKo5L',
            },
          },
        },
      ] as Record<string, MultiLanguageDocument>[],

      docOther: [
        {
          BBW: {
            name: $t('DPA - Accordo aggiuntivo per la protezione dei dati personali'),
            description: $t(
              "Il documento che regola il trattamento dei dati personali dei servizi Artera che ci deve essere inviato, compilato e firmato, all'indirizzo finance{'@'}artera.net",
            ),
            links: {
              it: 'https://files.artera.net/s/X6Gb3AzrZ8rSSSn',
              en: 'https://files.artera.net/s/X6Gb3AzrZ8rSSSn',
              fr: 'https://files.artera.net/s/X6Gb3AzrZ8rSSSn',
              de: 'https://files.artera.net/s/X6Gb3AzrZ8rSSSn',
            },
          },
        }, {
          BBW: {
            name: $t('NDA - Accordo di riservatezza'),
            description: $t(
              "Il documento che regola lo scambio di informazioni riservate che ci deve essere inviato, compilato e firmato, all'indirizzo finance{'@'}artera.net",
            ),
            links: {
              it: 'https://files.artera.net/s/X6b2dMPgxqYsoJz',
              en: 'https://files.artera.net/s/X6b2dMPgxqYsoJz',
              fr: 'https://files.artera.net/s/X6b2dMPgxqYsoJz',
              de: 'https://files.artera.net/s/X6b2dMPgxqYsoJz',
            },
          },
        },
      ] as Record<string, MultiLanguageDocument>[],
    };
  },

  computed: {
    billing() {
      return this.getDocVariant(this.docBilling);
    },

    other() {
      return this.getDocVariant(this.docOther);
    },

    contracts() {
      const contracts: Document[] = [];

      if (this.login.contratto_cliente) {
        contracts.push({
          name: $t('Termini e condizioni'),
          description: $t(
            'Le condizioni generali della nostra fornitura. ',
          ),
          contract: 'cliente',
        });
      }

      if (this.login.is_reseller) {
        contracts.push({
          name: $t('Partner program'),
          description: $t(
            'Le condizioni a te riservate in quanto partner.',
          ),
          contract: 'rivenditore',
        });
      }

      return contracts;
    },
  },

  methods: {
    getDocVariant(docs: Record<string, MultiLanguageDocument>[]) {
      const filteredDocs: Document[] = [];

      for (const docVariants of docs) {
        if (this.login.fatturante.strid && docVariants[this.login.fatturante.strid]) {
          filteredDocs.push({
            name: docVariants[this.login.fatturante.strid].name,
            description: docVariants[this.login.fatturante.strid].description ?? null,
            link: docVariants[this.login.fatturante.strid].links[this.login.lingua],
          });
        }
      }

      return filteredDocs;
    },
  },
});
</script>
