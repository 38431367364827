<template>
  <x-input
    :model-value="modelValue"
    :name="name"
    :custom-validity="validity"
    type="domain"
    :required="required"
    :placeholder="$t('Ad esempio dominio.ch')"
    @update:model-value="$emit('update:modelValue', $event as ValueType)"
  />
  <p v-if="showPrezzo && wizard.codiceProdottoDominio">
    <wizard-price-tag :product="wizard.getProdotto(wizard.codiceProdottoDominio)" />
  </p>
</template>

<script setup lang="ts">
import { useWizardQuestion } from '../composition';
import type { WizardInjection } from '../wizard.vue';
import WizardPriceTag from '../wizard-price-tag.vue';
import XInput from '../../x-input.vue';
import { computed } from 'vue';
import { $t as _t } from '@/i18n';

type ValueType = NonNullable<WizardInjection['nomeDominio']>;

defineProps<{
  modelValue: ValueType;
  name: string;
  required?: boolean;
  showPrezzo?: boolean;
}>();

defineEmits<{
  (name: 'update:modelValue', v: ValueType): void;
}>();

const { wizard } = useWizardQuestion();

const validity = computed(() => {
  if (wizard.path === 'dominio' && !wizard.dominioRegistrabile) {
    return _t("L'estensione del dominio non è fra quelle disponibili per la registrazione.");
  }
  return '';
});
</script>
