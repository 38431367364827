<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Scelta del prodotto')">
    <div v-if="wizard.askOs" class="row">
      <div class="col-md-2">
        <h3>{{ $t('Sistema Operativo') }}</h3>
        <wizard-question-os
          v-model="wizard.os"
          required
        />
      </div>
      <div v-if="wizard.askDurataFatturazione" class="col-md-2">
        <h3>{{ $t('Ricorrenza ') }}:</h3>
        <toggle
          v-model="wizard.fatturazioneAnnuale"
          :on-value="true"
          :off-value="false"
          :on-text="$t('Annuale')"
          :off-text="$t('Mensile')"
          off-class="btn-success"
        />
      </div>
      <div v-if="wizard.path === 'hosting' && wizard.os === 'linux'">
        <h3>{{ $t('Boost 20%') }}:</h3>
        <toggle
          v-model="wizard.square"
          :on-value="true"
          :off-value="false"
          :on-text="$t('Attiva')"
          :off-text="$t('No')"
          off-class="btn-danger"
        />
        <form-help-tooltip :text="$t('Vuoi attivare Artera Square: indirizzo IP fisso, multidominio, boost 20%')" />
      </div>
    </div>
    <div class="question-wrapper">
      <wizard-question-prodotti
        v-model="wizard.sceltaProdottoInfrastruttura"
        v-model:qt="wizard.storageQt"
        :ask-qt="wizard.askStorageQt"
        :prodotti="wizard.opzioniProdottiInfrastruttura()"
        name="sceltaProdottoInfrastruttura"
        show-feature
        variant="table"
        required
      />
    </div>
  </wizard-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';
import Toggle from '../../toggle.vue';
import WizardQuestionOs from '../questions/wizard-question-os.vue';
import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardForm from '../wizard-form.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

export default defineComponent({
  name: 'WizardStepProdottiInfrastruttura',

  components: {
    WizardQuestionOs,
    WizardQuestionProdotti,
    WizardForm,
    Toggle,
    FormHelpTooltip,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep({
        isEnabled: wizard => wizard.askProdotti,
      }),
    };
  },
});
</script>
