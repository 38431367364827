<template>
  <section>
    <div v-if="asyncComputed.payments.updating" class="blank-slate-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />

      <slot name="loading">
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Caricamento in corso') }}
        </h3>
      </slot>
    </div>

    <div v-else-if="!payments.length && !hideIfEmpty" class="blank-slate-pf">
      <p>{{ $t('Nessuna rata di pagamento presente') }}</p>
    </div>

    <template v-else-if="payments.length">
      <h3>
        {{ title }}<template v-if="paymethod"> ({{ paymethod }}) </template>
      </h3>

      <table class="table">
        <thead>
          <tr>
            <th>{{ $t('Descrizione') }}</th>
            <th>
              <sort-link v-model="s" v-model:reverse="r" field="amount">
                {{ $t('Importo') }}
              </sort-link>
            </th>
            <th>
              <sort-link v-model="s" v-model:reverse="r" field="data">
                {{ $t('Data') }}
              </sort-link>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in filtered" :key="payment.id">
            <td>
              <span v-if="payment.fattura">{{ payment.tipo }} nr. {{ payment.fattura }}: </span>
              {{ payment.descrizione }}
            </td>
            <td>{{ $currency(payment.amount, payment.currency) }}</td>
            <td>{{ $date(payment.data) }} ({{ $datefromnow(payment.data) }})</td>
            <td class="text-right">
              <template v-if="payment.tipo != 'Nota credito'">
                <router-link
                  v-if="payment.amount && payment.pending"
                  :to="{ name: 'payment', params: { id: payment.id } }"
                  class="btn btn-xs btn-success"
                >
                  {{ $t('Paga ora') }}
                </router-link>
                <template v-else>
                  {{ $t('Pagamento saldato') }}
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <h4>{{ $t('Pagamento tramite bonifico bancario') }}</h4>
      <p class="coordinate">{{ $t('Coordinate bancarie') }}:<br>{{ coordinateBancarie }}</p>
    </template>
  </section>
</template>

<style scoped>
.coordinate {
  white-space: pre-line;
}
</style>

<script lang="ts">
import { $t } from '@/i18n';
import { setupAsyncComputed } from '@common/asyncComputed';
import { by } from '@common/utils';
import SortLink from '../components/sort-link.vue';
import { useLoginStore } from '@/store/login';
import { PaymentsResource, type Payment } from '@/resources';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PaymentsList',

  components: {
    SortLink,
  },

  props: {
    title: {
      type: String,
      default: $t('Rate'),
    },
    paymethod: String,
    invoiceId: Number,
    pendingOnly: Boolean,
    hideIfEmpty: Boolean,
    currency: String,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        payments: {
          async get(this: any) {
            const payments = await new PaymentsResource({
              id_fattura: this.invoiceId,
              pending_only: this.pendingOnly ? 1 : 0,
            }).get();
            return payments;
          },
          default: [],
        },
      }),
    };
  },

  data() {
    return {
      s: 'date' as keyof Payment, // sort by
      r: true, // reverse sort
    };
  },

  computed: {
    filtered() {
      return this.payments.slice().sort(by(this.s, this.r));
    },

    coordinateBancarie() {
      const login = useLoginStore();
      const coordinate = login.fatturante.coordinate_bancarie;
      if (this.currency) {
        return `${this.currency == 'EUR' ? coordinate.banca_eur : coordinate.banca_chf} - Intestatario:  ${
          coordinate.intestatario
        }`;
      }

      return $t('Pagamenti in EURO: {0} \n Pagamenti in CHF: {1} \n Intestatario:  {2}', [
        coordinate.banca_eur,
        coordinate.banca_chf,
        coordinate.intestatario,
      ]);
    },
  },
});
</script>
