<template>
  <div v-if="prezzo > 0" v-bind="ouiaProps">
    {{ title }}:
    <s v-if="wizard.fatturaMe && prezzo !== prezzoScontato">
      {{ $currency(prezzo, wizard.customer?.currency) }}
    </s>
    &nbsp;
    <strong>
      {{ $currency(prezzoScontato, wizard.customer?.currency) }}
      <span v-if="wizard.customer === undefined || wizard.customer.vat > 0"> + {{ $t('IVA') }}</span> /
      {{ durataLabel }}
    </strong>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { $t } from '../../i18n';
import { useWizard } from './composition';
import type { Durata } from './wizard.vue';

export default defineComponent({
  name: 'WizardSummaryTotals',

  props: {
    durata: {
      type: String as PropType<Durata>,
      required: true,
    },
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      wizard: useWizard(),
    };
  },

  computed: {
    title() {
      return this.durata === 'Mensile' ? $t('Totale ricorrente mensile'): $t('Totale ricorrente annuale');
    },

    durataLabel() {
      return this.durata === 'Mensile' ? $t('Mese') : this.durata === 'Annuale' ? $t('Anno') : $t('Una tantum');
    },

    prezzo() {
      return this.wizard.totaleByDurata(this.durata, false);
    },

    prezzoScontato() {
      return this.wizard.totaleByDurata(this.durata, true);
    },
  },
});
</script>
