<template>
  <table class="table">
    <thead>
      <tr>
        <th><sort-link v-model="s" v-model:reverse="r" field="ragione_sociale">{{ $t('Nome cliente') }}</sort-link></th>
        <th>{{ $t('Partita Iva') }}</th>
        <th>{{ $t('Indirizzo') }}</th>
        <th>{{ $t('Provincia') }}</th>
        <th>{{ $t('Stato') }}</th>
        <th>{{ $t('Contatto') }}</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr v-for="customer in filtered" :key="customer.id">
        <td><span v-if="customer.ragione_sociale">{{ customer.ragione_sociale }}</span></td>
        <td><span v-if="customer.partita_iva">{{ customer.partita_iva }}</span></td>
        <td><span v-if="customer.indirizzo">{{ customer.indirizzo }}</span></td>
        <td><span v-if="customer.cap">{{ customer.cap }}</span>{{ ' ' }}<span v-if="customer.provincia">{{ customer.provincia }}</span></td>
        <td>{{ customer.stato }}</td>
        <td>{{ customer.contact_name }} <a v-if="customer.contact_mail" :href="`mailto:${customer.contact_mail}`">{{ customer.contact_mail }}</a></td>
        <td class="text-right">
          <button
            v-if="customer.login"
            :disabled="loading"
            type="button"
            class="btn btn-xs btn-primary"
            @click="login(customer.id)"
          >
            {{ $t('Log In') }}
          </button>
          <button
            v-else
            :disabled="loading"
            type="button"
            class="btn btn-xs btn-default"
            @click="requestAccess(customer.id, customer.ragione_sociale)"
          >
            {{ $t('Richiedi accesso') }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { ref, computed, type ComputedRef } from 'vue';
import { $t as _t } from '@/i18n';
import { http } from '../http';
import { useRouter, useRoute } from 'vue-router';
import type { MyCustomersAccess } from '../resources';
import { type CompanyAccess } from '@/resources';
import { useLoginStore } from '@/store/login';
import { loginDestination } from '../utils';
import SortLink from '../components/sort-link.vue';
import { any, by } from '@common/utils';

const props = defineProps<{
  customers: ({login: CompanyAccess}  & MyCustomersAccess)[];
  filter: string;
}>();

const router = useRouter();
const route = useRoute();

const loading = ref(false);
const s = ref('ragione_sociale') as ComputedRef<keyof MyCustomersAccess>; // sort by
const r = ref(false); // reverse sort

const filtered = computed(() => props.customers.filter(any(props.filter)).sort(by(s.value, r.value)));

async function requestAccess(id: number, azienda: string) {
  if ( !window.confirm(_t("Vuoi poter accedere all'area riservata di {0}? Confermando invieremo un'email di attivazione all'azienda, riceverai una notifica se la tua richiesta verrà accettata.", [azienda]))) {
    return;
  }

  loading.value = true;

  try {
    const data = new FormData();
    data.append('id', String(id));
    await http.post('/rest/my_customers/requestaccess', data);
  } finally {
    loading.value = false;
  }
};

async function login(id: number) {
  loading.value = true;

  try {
    const data = new FormData();
    data.append('id_azienda', String(id));

    const result = (await http.post('/api/auth/companylogin', data)).data.result;

    if (result == 'ok') {
      const login = useLoginStore();
      login.refresh();
      router.push(loginDestination(route.query));
    }
  } finally {
    loading.value = false;
  }
};
</script>
