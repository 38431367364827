<template>
  <div v-if="!isInIframe" class="login-pf-page">
    <div class="container-fluid">
      <div class="row">
        <div
          :class="{
            'col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3': variant === 'small',
            'col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1': variant === 'large',
          }"
        >
          <header class="login-pf-page-header">
            <img class="login-pf-brand" src="@/assets/logo.svg" alt="logo">
            <slot name="header" />
          </header>
          <div class="row">
            <div
              :class="{
                'col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2': variant === 'small',
                'col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1': variant === 'large',
              }"
            >
              <div class="card-pf">
                <slot />
              </div>

              <footer class="login-pf-page-footer">
                <ul class="login-pf-page-footer-links list-unstyled">
                  <li>
                    <a
                      class="login-pf-page-footer-link"
                      href="https://www.artera.net/it/termini-e-condizioni/"
                      target="_blank"
                      rel="noopener"
                    >{{ $t(termsOfUse) }}</a>
                  </li>
                  <li>
                    <a
                      class="login-pf-page-footer-link"
                      href="https://www.artera.net/it/informativa-privacy/"
                      target="_blank"
                      rel="noopener"
                    >{{ $t(privacyPolicy) }}</a>
                  </li>
                </ul>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-else>
    <slot name="header" />
    <slot />
  </template>
</template>

<style lang="scss">
html.login-pf,
html.login-pf body {
  background-color: #111;
  background-image: linear-gradient(transparent, #111 1080px),
    url(https://www.artera.net/wp-content/uploads/2018/05/main-photo.jpg);
  background-size: auto;

  .login-pf-brand {
    max-height: 130px;
  }

  .btn .spinner {
    display: inline-block;
    vertical-align: middle;
  }
}

.login-pf-page-header > img + * {
  margin-top: 20px;
}

.login-pf-page-footer-links {
  text-shadow: 0 0 4px black;
}

.login-buttons {
  margin: 0 auto;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > * {
    height: 40px;
  }

  > .separator {
    position: relative;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1em;

    > span {
      position: relative;
      background-color: white;
      z-index: 1;
      padding: 5px 10px;
    }

    &:after {
      display: block;
      content: '';
      border-top: 1px solid #ccc;
      position: relative;
      top: -21px;
      z-index: 0;
    }
  }

  .btn-linkedin {
    background-color: #0077b5;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0;
    text-decoration: none;

    &:hover {
      background-color: #0369a0;
    }

    &:active {
      background-color: #066093;
    }

    > span {
      flex-grow: 1;
      text-align: center;
    }

    > img {
      flex-grow: 0;
      background-color: #0077b5;
      border-radius: 4px;
      box-shadow: 2px 0 rgba(0,0,0,.1);
      padding: 5px;
      height: 40px;
      width: 40px;
    }
  }
}
</style>

<script lang="ts">
import { $t } from '@/i18n';
import { useAppStore } from '@/store/app';
import { defineComponent, type PropType } from 'vue';
import { useLoginStore } from '../store/login';

export default defineComponent({
  name: 'LoginLayout',

  props: {
    termsOfUse: {
      type: String,
      default: $t('Termini di Utilizzo'),
    },
    privacyPolicy: {
      type: String,
      default: $t('Privacy'),
    },
    variant: {
      type: String as PropType<'small' | 'large'>,
      default: 'small',
    },
  },

  setup() {
    const app = useAppStore();
    const login = useLoginStore();
    return {
      isInIframe: app.isInIframe,
      login,
    };
  },

  computed: {
    loggedIn() {
      return Boolean(this.login.id_azienda);
    },
  },
});
</script>
