<template>
  <login-layout>
    <header class="login-pf-header">
      <h1>{{ $t("Invito ad accedere all'area riservata.") }}</h1>
    </header>

    <pf-spinner v-if="asyncComputed.invite.updating" />

    <template v-else-if="invite.azienda">
      <div>
        {{ $t("Sei stato invitato ad accedere all'area riservata di {0}", [invite.azienda]) }}
        {{ $t('Questo ti consentirà di:') }}
        <ul>
          <li v-if="invite.grants">{{ $t('Gestire e consultare i dati tecnici dei servizi.') }}</li>
          <li v-if="invite.email_subscriptions?.includes('technical')">
            {{ $t("Ricevere in copia le notifiche tecniche dell'azienda") }}
          </li>
          <li v-if="invite.email_subscriptions?.includes('billing')">
            {{ $t("Ricevere in copia le notifiche amministrative dell'azienda") }}
          </li>
          <li v-if="invite.email_subscriptions?.includes('marketing')">
            {{ $t("Ricevere in copia le notifiche commerciali dell'azienda") }}
          </li>
        </ul>
      </div>

      <button type="button" class="btn btn-primary btn-block btn-lg" :disabled="sending" @click="submit">
        {{ $t('Accetta Invito') }}
        <div v-if="sending" class="spinner spinner-xs" />
      </button>
    </template>

    <p v-else>{{ $t("L'invito non è valido o è scaduto.") }}</p>

    <button type="button" class="btn btn-default btn-block btn-lg btn-logout" @click="next">{{ $t('Ignora') }}</button>
  </login-layout>
</template>

<style lang="scss" scoped>
.btn-logout {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { http } from '../../http';
import { setupAsyncComputed } from '@common/asyncComputed';
import { pop } from '@common/utils';
import { loginDestination } from '../../utils';
import LoginLayout from '../../components/login-layout.vue';
import { Resource } from '@/resources';
import { defineComponent } from 'vue';
import { useLoginStore } from '@/store/login';
import type { Invite } from '@/typings/resources';

export default defineComponent({
  name: 'AcceptInvitePage',

  components: {
    LoginLayout,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        invite: {
          async get(this: any): Promise<Invite> {
            if (!this.$route.params.antk) {
              return {};
            }

            return await new Resource('invites').get(this.$route.params.antk);
          },
          default: {} as Invite,
        },
      }),
    };
  },

  data() {
    return {
      sending: false,
    };
  },

  methods: {
    async submit() {
      this.sending = true;

      try {
        const data = new FormData();
        const antk = pop(this.$route.params.antk);
        if (antk) {
          data.append('antk', antk);
        }

        const result = (await http.post('/api/auth/companylogin', data)).data.result;

        if (result == 'ok') {
          const login = useLoginStore();
          await login.refresh();
          this.next();
        }
      } finally {
        this.sending = false;
      }
    },

    next() {
      this.$router.push(loginDestination(this.$route.query));
    },
  },
});
</script>
