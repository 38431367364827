<template>
  <page :name="$t('Conferma offerta')" :title="$t('Conferma offerta: {0}', [offer.titolo_offerta])" :state="[asyncComputed.offer, asyncComputed.company]">
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'offers' }">
        <li>
          <a @click="navigate">{{ $t('Offerte') }}</a>
        </li>
      </router-link>
    </template>
    <payment-report
      :result="reportQueryData.result"
      :error="reportQueryData.error"
      route="offers"
      :success="$t('Offerta confermata')"
      :link="$t('Torna alle offerte')"
    >
      <div v-if="askBilling" class="checkout-step">
        <h2>
          {{ $t('Dati di fatturazione') }}
          <span v-if="company.hasCompleteBillingData"><ok-icon /></span>
        </h2>
        <billing-form v-if="!company.hasCompleteBillingData" embed-for="order" @submit="refresh" />
      </div>

      <x-form
        method="post"
        action="/api/pay_offer"
        :prevent-submit="!canOrder"
        :submit-help="submitHelp"
        :submit-text="submitText"
        @before-submit="saveToLocalStorage"
      >
        <div class="checkout-step">
          <h2>{{ $t('Riepilogo offerta') }}</h2>
          <input type="hidden" name="idOfferta" :value="offer.id">
          <offer-detail
            :offerta="offer"
          />
          <payment-methods
            v-if="offer.stato_ordine !== 'concluso'"
            v-model="method"
            enable-cashback
            :totale-offerta-ivato="offer.gran_totale_ivato"
            :currency="offer.currency"
            :free-period="offer.free_period"
            :testing-mode="offer.testing_mode"
          />
        </div>
      </x-form>
    </payment-report>
  </page>
</template>

<style scoped>
.checkout-step {
  margin-bottom: 30px;
}

.checkout-step h2 {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.checkout-step h2 span {
  font-size: 15px;
}
</style>

<script lang="ts">
import { ref, provide, defineComponent } from 'vue';
import { until, invoke } from '@vueuse/core';

import Page from '../components/page.vue';
import BillingForm from '../components/billing-form.vue';
import OfferDetail from '../components/offer-detail.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Methods} from '../components/payment-methods.vue';
import XForm from '../components/x-form.vue';
import { setupAsyncComputed, asyncComputed } from '@common/asyncComputed';
import { $t } from '../i18n';
import { type Company, CompanyInjectionKey, CompanyResource, type Offer, OffersResource } from '@/resources';
import { pop } from '@common/utils';
import { usePaypalStore } from '@/store/paypal';
import OkIcon from '@vue-patternfly/icons/ok-icon';

export default defineComponent({
  name: 'ConfirmOfferPage',

  components: {
    Page,
    BillingForm,
    OfferDetail,
    PaymentReport,
    PaymentMethods,
    XForm,
    OkIcon,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const askBilling = ref(false);

    const offer = asyncComputed(async function() {
      const offer: Offer = {
        ...await new OffersResource().get(props.id),
      };
      return offer;
    }, {} as Offer);

    const company = asyncComputed(
      async function() {
        await until(() => offer.state.updating || offer.state.pending).toBe(false);
        if (offer.state.error) {
          throw new Error('Error loading offer');
        }
        return await new CompanyResource().get(offer.result.value.id_azienda);
      },
      {} as Company,
    );

    invoke(async() => {
      await until(company.result).toMatch(v => Boolean(v.id));
      askBilling.value = !company.result.value.hasCompleteBillingData;
    });

    provide(CompanyInjectionKey, company.result.value);

    return {
      askBilling,
      ...setupAsyncComputed({
        offer,
        company,
      }),
    };
  },

  data() {
    return {
      method: undefined as Methods | undefined,
    };
  },

  computed: {
    canOrder() {
      return this.company.hasCompleteBillingData !== false && this.offer.stato_ordine !== 'concluso';
    },

    submitHelp() {
      return this.method && this.method != 'custom'
        ? $t("Verrai reindirizzato alla pagina di pagamento. L'operazione può durare qualche secondo.")
        : '';
    },

    submitText() {
      if (this.offer.stato_ordine === 'concluso') {
        return $t('Ordine già confermato');
      }
      return this.offer.free_period > 0 || this.method === 'custom' ? $t('Conferma offerta') : $t('Procedi al pagamento');
    },

    reportQueryData() {
      const result = pop(this.$route.query.res);
      return {
        result: result ? Number(result) : undefined,
        error: pop(this.$route.query.err) ?? undefined,
      };
    },
  },

  methods: {
    async refresh() {
      await this.asyncComputed.offer.update();
      await this.asyncComputed.company.update();
    },

    saveToLocalStorage() {
      if (this.method === 'axerve-paypal') {
        const paypal = usePaypalStore();
        paypal.save();
      }
    },
  },
});
</script>
