<template>
  <page
    v-model:filter="q"
    :name="$t('Fatture e pagamenti')"
    :empty="invoices.length <= 0"
    :state="asyncComputed"
    with-filter
  >
    <payments-list :invoice-id="0" pending-only hide-if-empty :title="$t('Pagamenti')" />

    <div class="list-group list-view-pf list-view-pf-view">
      <pf-list-group-item
        v-for="(row, i) in filtered"
        ref="listItems"
        :key="row.id"
        v-model:expanded="row.expanded"
        :index="i"
        expandable
        stacked
      >
        <pf-list-item>
          <template #left>
            <pf-tooltip>
              <template #content>{{ tooltip(row) }}</template>
              <span class="fa list-view-pf-icon-md">
                <help-icon v-if="row.type == 'Nota credito' || (row.payments - row.settled > 0 && row.pastdue == 0)" />
                <ok-icon v-else-if="row.payments - row.settled <= 0" />
                <warning-triangle-icon v-else />
              </span>
            </pf-tooltip>
          </template>

          <template #heading> {{ row.type }} #{{ row.nr }} </template>

          <template #description>
            {{ $t('del {0}', [$date(row.date)]) }}
          </template>

          <template #additional-info>
            <pf-list-item-additional-info stacked>
              <span>{{ $t('Importo') }}</span>
              <strong>{{ $currency(row.amount, row.currency) }}</strong>
            </pf-list-item-additional-info>

            <pf-list-item-additional-info v-if="row.type != 'Nota credito'" stacked>
              <span>{{ $t('Rate saldate') }}</span>
              <strong>{{ row.settled }} / {{ row.payments }}</strong>
            </pf-list-item-additional-info>
          </template>
        </pf-list-item>

        <template #action>
          <a v-if="row" target="_blank" :href="`/rest/invoice-pdf/${row.id}`" class="btn btn-primary">
            <download-icon />
            {{ $t('Download PDF') }}
          </a>
          <pf-button v-if="row.type !== 'Nota credito' && row.payments - row.settled > 0" variant="success" @click="expand(row.id)">
            {{ $t('Paga ora') }}
          </pf-button>
        </template>

        <template #expansion>
          <invoice-detail v-if="row" :invoice-id="row.id" />
          <payments-list
            v-if="row && row.type != 'Nota credito'"
            :invoice-id="row.id"
            :paymethod="row.paymethod"
            :currency="row.currency"
          />
        </template>
      </pf-list-group-item>
    </div>
  </page>
</template>

<style lang="scss" scoped>
.list-view-pf-additional-info-item.list-view-pf-additional-info-item-stacked strong {
  font-size: 1.3em;
  margin-right: 0;
}
</style>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import { any, by } from '@common/utils';
import Page from '../components/page.vue';
import PaymentsList from '../components/payments-list.vue';
import InvoiceDetail from '../components/invoice-detail.vue';
import { Resource, type GenericResource } from '@/resources';
import { defineComponent } from 'vue';
import { $t } from '@/i18n';
import HelpIcon from '@vue-patternfly/icons/help-icon';
import OkIcon from '@vue-patternfly/icons/ok-icon';
import WarningTriangleIcon from '@vue-patternfly/icons/warning-triangle-icon';
import DownloadIcon from '@vue-patternfly/icons/download-icon';

export default defineComponent({
  name: 'InvoicesPage',

  components: {
    Page,
    PaymentsList,
    InvoiceDetail,
    HelpIcon,
    OkIcon,
    WarningTriangleIcon,
    DownloadIcon,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        invoices: {
          async get() {
            const invoices = await new Resource('invoices').get();
            return invoices.map(i => {
              i.expanded = false;
              return i;
            });
          },
          default: [] as GenericResource[],
        },
      }),
    };
  },

  data() {
    return {
      q: '', // search query
      s: 'date', // sort by
      r: true, // reverse sort
    };
  },

  computed: {
    filtered() {
      return this.invoices.filter(any(this.q)).sort(by(this.s, this.r));
    },
  },

  methods: {
    tooltip(invoice: GenericResource) {
      if (invoice.type == 'Nota credito' || (invoice.payments - invoice.settled > 0 && invoice.pastdue == 0)) {
        return null;
      }
      return invoice.payments - invoice.settled <= 0
        ? $t('Fattura saldata')
        : $t('Contiene rate non saldate e scadute');
    },

    expand(id: number) {
      const invoice = this.invoices.find((f) => f.id === id);

      if (invoice) {
        invoice.expanded = !invoice.expanded;
      }
    },
  },
});
</script>
