<template>
  <pf-modal form :show="show" :title="$t('Attivazione autenticazione a due fattori')" @close="emit('update:show', false)" @submit.prevent="verify">
    <pf-spinner v-if="!totp" />

    <div v-if="totp" style="display: flex; flex-direction: column; align-items: center;">
      <div class="form-group">
        <label for="anagraficaTwoFA">{{ $t("Scansiona il codice QR con app di autenticazione") }}</label>
        <img :src="`/qrcode/${totp.uri}.png`" alt="QR code" width="300">
      </div>

      <div class="form-group">
        <label for="anagraficaTwoFA">{{ $t('Oppure copia il codice manualmente') }}</label>
        <input id="anagraficaTwoFA" class="form-control" readonly type="text" :value="totp.secret" @click="secretClick">
      </div>

      <div class="form-group">
        <label for="twoFAVerifyCode">{{ $t("Inserisci il codice generato dall'app") }}</label>
        <input id="twoFAVerifyCode" v-model="verifyCode" required class="form-control">
      </div>
    </div>

    <template #footer>
      <pf-button @click="emit('update:show', false)">
        {{ $t('Annulla') }}
      </pf-button>

      <pf-button type="submit" variant="primary">
        {{ $t('Procedi') }}
      </pf-button>
    </template>
  </pf-modal>
</template>

<style scoped>
.form-group, img {
  width: 300px;
}
</style>

<script lang="ts" setup>
import { http } from '@/http';
import { $t as _t } from '@/i18n';
import { useAppStore } from '@/store/app';
import { setupAsyncComputed } from '@common/asyncComputed';
import { copyToClipBoard } from '@common/utils';
import { ref } from 'vue';

defineProps<{
  show: boolean;
  enabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:show', value: boolean): void
  (e: 'update:enabled', value: boolean): void
}>();

const { totp } = setupAsyncComputed({
  totp: {
    async get(this: any): Promise<{uri: string; secret: string} | null> {
      const totp = (await http.get('/api/auth/totp')).data;
      totp.uri = btoa(totp.uri);
      return totp;
    },
    default: null,
  },
});

const verifyCode = ref('');

function secretClick(e: Event) {
  if (!(e.target instanceof HTMLInputElement)) {
    return;
  }
  e.target.select();
  copyToClipBoard(e.target.value);

  const app = useAppStore();
  app.toast(_t('Codice copiato nella clipboard'), 'info');
}

async function verify() {
  const data = new FormData();
  data.append('totp', verifyCode.value);
  const response = await http.post('/api/auth/totp', data);
  if (response.data.level === 'success') {
    emit('update:show', false);
    emit('update:enabled', true);
  }
}
</script>
