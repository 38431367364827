<template>
  <section v-bind="(ouiaProps as any)">
    <ul :class="navClasses" role="tablist">
      <template v-for="(tab, i) in groupedTabs" :key="i">
        <pf-dropdown v-if="isTabGroup(tab)" v-show="!tab.hidden" role="presentation" tag="li" :class="getTabClasses(tab)">
          <a class="dropdown-toggle" role="tab" href="#" @click.prevent>
            {{ tab.group }}
            <span class="caret" />
          </a>
          <template #dropdown>
            <li
              v-for="(subTab, j) in tab.tabs"
              v-show="!subTab.hidden"
              :key="`${i}_${j}`"
              :class="getTabClasses(subTab, true)"
            >
              <a href="#" @click.prevent="select(tabs.indexOf(subTab))">
                {{ subTab.title }}
              </a>
            </li>
          </template>
        </pf-dropdown>
        <li v-else v-show="!tab.hidden" role="presentation" :class="getTabClasses(tab)">
          <a v-if="tab.$slots.title" :id="tab.uid" role="tab" href="#" @click.prevent="select(tabs.indexOf(tab))" />
          <a v-else role="tab" href="#" @click.prevent="select(tabs.indexOf(tab))" v-text="tab.title" />
        </li>
      </template>
      <li v-if="!justified && $slots['nav-right']" class="pull-right">
        <slot name="nav-right" />
      </li>
    </ul>
    <div :class="contentClasses">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
import { type ComponentPublicInstance, defineComponent, type InjectionKey, provide } from "vue";
import { provideChildrenTracker, type ChildrenTrackerInjectionKey, useManagedProp } from '@common/use';
import { ouiaProps, useOUIAProps } from "@common/ouia";

type TabComponent = ComponentPublicInstance<
  /* Props: */{
    title: string;
    disabled: boolean;
    tabClasses: Record<string, boolean>;
    group: string;
    pullRight: boolean;
    hidden: boolean;
  },
  /* RawBindings: */object,
  /* Data: */{
    uid: string;
    active: boolean;
    transition: number;
  },
  /* Computed: */Record<string, never>,
  /* Methods: */{
    show?: () => void;
  }
>;

type TabGroup = {
  group: string;
  tabs: TabComponent[];
  active?: boolean;
  pullRight?: boolean;
  hidden?: boolean;
};

export const TabsInjectionKey = Symbol("TabsInjectionKey") as ChildrenTrackerInjectionKey;
export const TabsOnChangeInjectionKey = Symbol("TabsOnChangeInjectionKey") as InjectionKey<() => void>;

export default defineComponent({
  name: 'PfTabs',

  props: {
    modelValue: {
      type: Number,
      validator: (v: any) => v >= 0,
      default: undefined,
    },
    transition: {
      type: Number,
      default: 150,
    },
    justified: Boolean,
    pills: Boolean,
    stacked: Boolean,
    customNavClass: { type: null, default: undefined },
    customContentClass: { type: null, default: undefined },
    beforeChange: { type: Function, default: undefined },
    ouiaId: ouiaProps.ouiaId,
    ouiaSafe: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue', 'change', 'changed'],

  setup(props, {emit}) {
    const activeIndex = useManagedProp('modelValue', 0);
    provide(TabsOnChangeInjectionKey, () => emit('changed', activeIndex.value));

    return {
      ouiaProps: useOUIAProps(props),
      tabs: provideChildrenTracker<TabComponent>(TabsInjectionKey),
      activeIndex,
    };
  },

  computed: {
    navClasses() {
      const tabClasses = {
        nav: true,
        'nav-justified': this.justified,
        'nav-tabs': !this.pills,
        'nav-pills': this.pills,
        'nav-stacked': this.stacked && this.pills,
      };
      const customNavClass = this.customNavClass;
      if (customNavClass) {
        if (typeof customNavClass === 'string') {
          return {
            ...tabClasses,
            [customNavClass]: true,
          };
        } else {
          return {
            ...tabClasses,
            ...customNavClass,
          };
        }
      } else {
        return tabClasses;
      }
    },

    contentClasses() {
      const contentClasses = {
        'tab-content': true,
      };
      const customContentClass = this.customContentClass;
      if (customContentClass) {
        if (typeof customContentClass === 'string') {
          return { ...contentClasses, [customContentClass]: true };
        } else {
          return { ...contentClasses, ...customContentClass };
        }
      } else {
        return contentClasses;
      }
    },

    groupedTabs() {
      let tabs: (TabComponent | TabGroup)[] = [];
      const hash: Record<string, number> = {};

      this.tabs.forEach(tab => {
        if (tab.group) {
          if (Object.hasOwnProperty.call(hash, tab.group)) {
            (tabs[hash[tab.group]] as TabGroup).tabs.push(tab);
          } else {
            tabs.push({
              tabs: [tab],
              group: tab.group,
            });
            hash[tab.group] = tabs.length - 1;
          }
          if (tab.active) {
            (tabs[hash[tab.group]] as TabGroup).active = true;
          }
          if (tab.pullRight) {
            (tabs[hash[tab.group]] as TabGroup).pullRight = true;
          }
        } else {
          tabs.push(tab);
        }
      });
      tabs = tabs.map(tab => {
        if (this.isTabGroup(tab)) {
          tab.hidden = tab.tabs.filter(v => v.hidden).length === tab.tabs.length;
        }
        return tab;
      });
      return tabs;
    },
  },

  watch: {
    activeIndex() {
      this.selectCurrent();
    },

    tabs(tabs: TabComponent[]) {
      tabs.forEach((tab, index) => {
        tab.transition = this.transition;
        if (index === this.activeIndex) {
          tab.show?.();
        }
      });
      this.selectCurrent();
    },
  },

  mounted() {
    this.selectCurrent();
  },

  methods: {
    isTabGroup(tab: TabComponent | TabGroup): tab is TabGroup {
      console.log((tab as TabComponent).disabled);
      return Array.isArray((tab as TabGroup).tabs);
    },

    getTabClasses(tab: TabComponent | TabGroup, isSubTab = false) {
      const defaultClasses = {
        active: tab.active,
        disabled: !this.isTabGroup(tab) && tab.disabled,
        'pull-right': tab.pullRight && !isSubTab,
      };

      if (this.isTabGroup(tab)) {
        return defaultClasses;
      }

      // return with new classes added to tab
      return { ...defaultClasses, ...tab.tabClasses };
    },

    selectCurrent() {
      let found = false;
      this.tabs.forEach((tab, index) => {
        if (index === this.activeIndex) {
          found = !tab.active;
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
      if (found) {
        this.$emit('change', this.activeIndex);
      }
    },

    selectValidate(index: number) {
      if (typeof this.beforeChange === 'function') {
        this.beforeChange(this.activeIndex, index, (result: never) => {
          if (result === null || typeof result === 'undefined') {
            this.$select(index);
          }
        });
      } else {
        this.$select(index);
      }
    },

    select(index: number) {
      if (!this.tabs[index].disabled && index !== this.activeIndex) {
        this.selectValidate(index);
      }
    },

    $select(index: number) {
      this.activeIndex = index;
    },
  },
});
</script>
