<template>
  <Teleport to="body">
    <pf3-tooltip v-if="target" :text="content" :target="target" />
  </Teleport>

  <void :alter="passthrough">
    <slot />
  </void>

  <capture-text @change="$event => content = $event">
    <slot name="content" />
  </capture-text>
</template>

<script lang="ts" setup>
import { PfTooltip as Pf3Tooltip } from 'vue-patternfly';
import CaptureText from './CaptureText.vue';
import type { VNode, VNodeArrayChildren, Ref } from 'vue';
import { isVNode, ref } from 'vue';
import { onMounted } from 'vue';

defineSlots<{
  default: (props: Record<never, never>) => any;
  content: (props: Record<never, never>) => any;
}>();

const target: Ref<HTMLElement | null> = ref(null);
const targetNode: Ref<VNode | null> = ref(null);
const content = ref('');

function passthrough(nodes: VNode[]): VNode[] {
  targetNode.value = findTargetNode(nodes);
  return nodes;
}

function findTargetNode(nodes: VNodeArrayChildren): VNode | null {
  for (const node of nodes) {
    if (!isVNode(node)) {
      continue;
    }
    if (node.el instanceof HTMLElement || typeof node.type === 'string' || typeof node.type === 'object') {
      return node;
    }
    if (Array.isArray(node.children)) {
      return findTargetNode(node.children);
    }
  }
  return null;
}

onMounted(() => (target.value = targetNode.value?.el as any));
</script>
