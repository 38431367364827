<template>
  <page :name="$t('Dashboard')" :state="asyncComputed" hide-breadcrumbs ignore-failed>
    <template v-if="company.id">
      <p>{{ $t("Benvenuto nell'utenza del cliente: {0}", [company.ragione_sociale]) }}</p>
    </template>

    <div v-else class="dashboard-pf-page">
      <div class="row cards-pf">
        <h2 class="subtitle">
          {{ $t('Di cosa hai bisogno?') }}
        </h2>

        <div class="row">
          <div v-if="login.is_reseller && !login.complete_skill" class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Aiutaci a passarti più clienti')">
              <router-link :to="{ name: 'my_skills' }">
                <puzzle-piece-icon />
                {{ $t('Compila le tue skill') }}
              </router-link>
            </pf-card>
          </div>
          <div v-if="!login.is_reseller" class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Acquistare un servizio / ricevere un\'offerta')">
              <router-link :to="{ name: 'diy-offer' }">
                <wand-magic-icon />
                {{ $t('Vai al wizard') }}
              </router-link>
            </pf-card>
          </div>
          <div v-if="login.is_reseller" class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Attivare un prodotto in prova')">
              <router-link :to="{ name: 'product-testing' }">
                <layer-group-icon />
                {{ $t('Configura il prodotto') }}
              </router-link>
            </pf-card>
          </div>
          <div class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Assistenza / Consulenza tecnica')">
              <router-link :to="{ name: 'tickets' }">
                <ticket-icon />
                {{ $t('Apri un ticket') }}
              </router-link>
            </pf-card>
          </div>
          <div class="shortcut col-md-6 col-lg-3">
            <pf-card :title="$t('Fissare un appuntamento')">
              <a href="https://artera.appointlet.com/b/sergio-ravera" target="_blank">
                <calendar-day-icon />
                {{ $t('Decidi tu quando') }}
              </a>
            </pf-card>
          </div>
          <div v-if="askPartner" class="shortcut col-md-12 col-lg-3">
            <pf-card :title="$t('Diventare partner Artera')">
              <router-link :to="{ name: 'become_partner' }">
                <handshake-icon />
                {{ $t('Compila i tuoi dati e diventa partner') }}
              </router-link>
            </pf-card>
          </div>
        </div>
      </div>

      <div v-if="registrant_data.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            <triangle-exclamation-icon /> {{ $t('Prodotti in fase di attivazione con dati mancanti') }}
          </h2>

          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('Per completare la registrazione di questi nomi a dominio ci occorrono alcuni dati') }}
                  </div>
                </div>

                <div v-if="login.id_azienda" class="list-group list-view-pf bordered">
                  <pf-list-group-item v-for="product in registrant_data" :key="product.id">
                    <pf-list-item>
                      <template #heading>
                        <span :title="product.dominio">{{ $t('Registrazione dominio {0}', [product.dominio]) }}</span>
                      </template>
                      <template #additional-info>
                        <pf-list-item-additional-info expandable>
                          {{ $t('Inserisci i dati del registrante') }}
                          <template #expansion>
                            <form-domain-registrant :domain="product" :id-azienda="Number(login.id_azienda)" @submit="asyncComputed.products.update" />
                          </template>
                        </pf-list-item-additional-info>
                      </template>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="testing.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            {{ $t('Prodotti in prova') }}
          </h2>
          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('Questi prodotti sono in prova gratuita') }}
                  </div>
                </div>
                <div class="list-group list-view-pf bordered max-height">
                  <pf-list-group-item v-for="product in testing" :key="product.id">
                    <pf-list-item class="row">
                      <dl class="col-md-2">
                        <dt>{{ $t('Ordine') }}</dt>
                        <dd>#{{ product.id_ordine }}</dd>
                      </dl>

                      <dl class="col-md-4">
                        <dt>{{ $t('Prodotto') }}</dt>
                        <dd>{{ product.nome }}</dd>
                      </dl>

                      <dl v-if="product.dominio" class="col-md-2">
                        <dt>{{ $t('Dominio') }}</dt>
                        <dd>{{ product.dominio }}</dd>
                      </dl>


                      <dl class="col-md-3">
                        <dt v-if="parseDateTime(product.data_fine).diff(now, ['days']).days < 0">{{ $t('Periodo di prova scaduto') }}</dt>
                        <dt v-else> {{ $t('Periodo di prova in corso') }}</dt>
                        <dd v-if="product.disdetto" class="text-danger">{{ $t('Questo prodotto in prova verrà sospeso e poi cancellato al termine del periodo di test.') }}</dd>
                        <dd v-else class="text-success">{{ $t('Questo prodotto in prova verrà rinnovato e fatturato al termine del periodo di test.') }}</dd>
                      </dl>

                      <dl class="col-md-1 action">
                        <dd v-if="product.disdetto">
                          <pf-button
                            variant="success"
                            :disabled="!login.grants.billing"
                            @click.prevent="changeAdministrativeState(product, 'attivo')">
                            {{ $t('Conferma') }} {{ ' ' }}
                            <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per confermare il prodotto. Richiedili ad un utente amministratore.')" />
                          </pf-button>
                        </dd>
                        <dd v-else class="col-md-1">
                          <pf-button
                            variant="danger"
                            :disabled="!login.grants.billing"
                            @click.prevent="changeAdministrativeState(product, 'non rinnovare')">
                            {{ $t('Non rinnovare') }} {{ ' ' }}
                            <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per disdire il prodotto. Richiedili ad un utente amministratore.')" />
                          </pf-button>
                        </dd>
                      </dl>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div v-if="pending.length > 0" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            {{ $t('Prodotti in fase di attivazione') }}
          </h2>

          <div class="row">
            <div class="col-lg-12">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="panel-subtitle">
                    {{ $t('I nostri tecnici provvederanno ad attivare i seguenti prodotti a breve.') }}
                  </div>
                </div>

                <div class="list-group list-view-pf bordered max-height">
                  <pf-list-group-item v-for="product in pending" :key="product.id">
                    <pf-list-item class="row">
                      <dl class="col-md-2">
                        <dt>{{ $t('Ordine') }}</dt>
                        <dd>#{{ product.id_ordine }}</dd>
                      </dl>

                      <dl class="col-md-5">
                        <dt>{{ $t('Prodotto') }}</dt>
                        <dd>{{ product.nome }}</dd>
                      </dl>

                      <dl v-if="product.dominio" class="col-md-5">
                        <dt>{{ $t('Dominio') }}</dt>
                        <dd>{{ product.dominio }}</dd>
                      </dl>
                    </pf-list-item>
                  </pf-list-group-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div v-if="expiring.length || payments.length" class="row">
        <div class="col-lg-12">
          <h2 class="subtitle">
            {{ $t('Nei prossimi giorni') }}
          </h2>
        </div>

        <div v-if="expiring.length" class="col-lg-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('Prodotti scaduti o in scadenza nei prossimi 30 giorni') }}
              </h3>
            </div>

            <ul class="list-group max-height">
              <li v-for="product in expiring" :key="product.id" class="list-group-item">
                <span class="pull-right">{{ $datefromnow(product.data_fine) }}</span>
                {{ product.nome }} {{ product.dominio }}
              </li>
            </ul>

            <div class="panel-footer text-right">
              <router-link to="/products">
                {{ $t('Visualizza tutti') }} <arrow-right-icon />
              </router-link>
            </div>
          </div>
        </div>

        <div v-if="payments.length" class="col-lg-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('Prossimi pagamenti') }}
              </h3>
            </div>

            <div class="list-group clickable-list max-height">
              <router-link
                v-for="payment in payments"
                :key="payment.id"
                :to="{ name: 'payment', params: { id: payment.id } }"
                class="list-group-item"
              >
                <circle-arrow-right-icon class="pull-right" />

                <span class="pull-right">{{ $datefromnow(payment.data) }}</span>
                <span v-if="payment.fattura">{{ $t('Fattura nr. {0}:', [payment.fattura]) }} </span>
                {{ payment.descrizione }} = {{ $currency(payment.amount, login.currency) }}
              </router-link>
            </div>

            <div class="panel-footer text-right">
              <router-link to="/invoices"> {{ $t('Visualizza tutti') }} <arrow-right-icon /> </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div>
  </page>
</template>

<style lang="scss" scoped>
@import 'patternfly/color-variables';

.panel-body,
.list-group.max-height {
  max-height: 329px;
  overflow-y: auto;
}

.panel > .list-view-pf.bordered {
  background-color: $color-pf-black-200;
}

.panel .list-view-pf .action dd {
  padding: 0;
}

.cards-pf {
  padding-left: 20px;
  padding-right: 20px;

  > .row {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { setupAsyncComputed } from '@common/asyncComputed';
import { by } from '@common/utils';

import Page from '@/components/page.vue';
import FormDomainRegistrant from '../components/form-domain-registrant.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';
import ArrowRightIcon from '@vue-patternfly/icons/arrow-right-icon';
import PuzzlePieceIcon from '@vue-patternfly/icons/puzzle-piece-icon';
import TicketIcon from '@vue-patternfly/icons/ticket-icon';
import WandMagicIcon from '@vue-patternfly/icons/wand-magic-icon';
import LayerGroupIcon from '@vue-patternfly/icons/layer-group-icon';
import CalendarDayIcon from '@vue-patternfly/icons/calendar-day-icon';
import HandshakeIcon from '@vue-patternfly/icons/handshake-icon';
import CircleArrowRightIcon from '@vue-patternfly/icons/circle-arrow-right-icon';

import { useCompanyStore } from '@/store/company';
import { useLoginStore } from '@/store/login';
import { ProductOrdersResource, type ProductOrder, PaymentsResource, type Payment } from '@/resources';
import { DateTime } from 'luxon';
import { parseDateTime } from '@common/date';
import { changeAdministrativeState } from './products.vue';

type DomainProductOrder = Omit<ProductOrder, 'dominio'> & {dominio: string};

export default defineComponent({
  name: 'DashboardPage',

  components: {
    Page,
    FormDomainRegistrant,
    FormHelpTooltip,
    TriangleExclamationIcon,
    ArrowRightIcon,
    PuzzlePieceIcon,
    WandMagicIcon,
    TicketIcon,
    CalendarDayIcon,
    LayerGroupIcon,
    CircleArrowRightIcon,
    HandshakeIcon,
  },

  setup() {
    const company = useCompanyStore();
    const login = useLoginStore();

    return {
      company,
      login,
      ...setupAsyncComputed({
        products: {
          get() {
            return new ProductOrdersResource({ sort: 'data_fine' }).get();
          },
          default: [] as ProductOrder[],
        },

        payments: {
          async get() {
            if (!login.grants.billing) {
              return [] as Payment[];
            }
            return await (new PaymentsResource({
              expiring: 30,
            })).get();
          },
          default: [] as Payment[],
        },
      }),
    };
  },

  data() {
    return {
      now: DateTime.now(),
    };
  },

  computed: {
    testing(): ProductOrder[] {
      return this.products.filter(p => p.free_period > 0).sort(by('data_fine'));
    },

    registrant_data(): DomainProductOrder[] {
      const products: DomainProductOrder[] = this.products
        .filter(p => p.missing_data && p.registrazione_dominio && p.dominio !== null) as DomainProductOrder[];

      return products.sort(by('data_fine'));

    },

    pending(): ProductOrder[] {
      return this.products
        .filter(p => p.stato_tecnico === 'attivare')
        .sort(by('data_fine'));
    },

    expiring(): ProductOrder[] {
      return this.products
        .filter(p => parseDateTime(p.data_fine).diff(this.now, ['days']).days < 31)
        .sort(by('data_fine'));
    },


    askPartner(): boolean {
      return !this.login.is_reseller && !this.login.has_parent;
    },
  },

  methods:{
    parseDateTime,
    changeAdministrativeState,
  },
});
</script>
