<template>
  <page :name="$t('Tickets')" :description="$t('Richiedi supporto al team Artera')" :state="asyncComputed">
    <div class="ticket-wrapper">
      <div class="ticket-description">
        <i18n-t
          keypath="Prima di aprire un nuovo ticket al nostro centro di assistenza, assicurati di aver letto le {0} che abbiamo preparato per i nostri clienti. La soluzione al tuo problema potrebbe già essere descritta in una delle sezioni dedicate."
          tag="p"
        >
          <a href="https://kb.artera.net/" target="_blank" rel="noopener">{{ $t('FAQ') }}</a>
        </i18n-t>
      </div>

      <div class="ticket-open">
        <pf-button :to="{ name: 'ticket', params: { id: 'new' } }" large>
          {{ $t('Apri un nuovo ticket') }}
        </pf-button>
      </div>
    </div>

    <div v-if="tickets.length > 0" class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title"><i class="icon icon-ticket" /> {{ $t('Ultimi ticket aperti') }}</h3>
      </div>

      <div class="list-group clickable-list">
        <div class="list-pf">
          <router-link
            v-for="t in tickets"
            v-slot="{ navigate, href }"
            :key="t.id"
            custom
            :to="{ name: 'ticket', params: { id: t.id } }"
          >
            <div class="list-pf-item" @click="navigate">
              <div class="list-pf-container">
                <div class="list-pf-content list-pf-content-flex">
                  <div class="list-pf-content-wrapper">
                    <div class="list-pf-main-content">
                      {{ t.titolo }}
                    </div>

                    <div class="list-pf-additional-content">
                      <div>
                        <small>{{ $t('Riferimento') }}</small>
                        <div>
                          <a :href="href" @click="navigate">#<strong>{{ t.id }}</strong></a>
                        </div>
                      </div>

                      <div>
                        <small>{{ $t('Apertura') }}</small>
                        <div>
                          <strong>{{ $datefromnow(t.data) }}</strong>
                        </div>
                      </div>

                      <div>
                        <small>{{ $t('Stato') }}</small>
                        <div>
                          <span class="label" :class="[statuslabel(t.stato)]">{{
                            t.stato == 'aperto' ? $t('Aperto') : $t('Chiuso')
                          }}</span>
                          <span v-if="t.urgente" class="label btn-danger">
                            {{ $t('Urgente') }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </page>
</template>

<style lang="scss" scoped>

.list-pf {
  overflow-y: auto;
  max-height: 487px;
}

.row.ticket-contact {
  margin-right: 59px;
}

.ticket-open {
  text-align: center;
  margin-bottom: 20px;
}

.list-pf-content-wrapper .label.btn-danger {
  margin-left: 5px;
}
</style>

<script lang="ts">
import Page from '../components/page.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { Resource } from '@/resources';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TicketsPage',

  components: {
    Page,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        tickets: {
          get() {
            return new Resource('tickets').get();
          },
          default: [],
        },
      }),
    };
  },

  methods: {
    statuslabel(input: string) {
      const s = input ? input.toLowerCase() : '';
      if (s === 'aperto') {
        return 'label-success';
      }
      return 'label-default';
    },
  },
});
</script>
