<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Location')">
    <div class="question-wrapper">
      <h3>{{ $t('Server farm di Produzione') }}</h3>
      <wizard-question-farm v-model="wizard.sceltaFarmProduzione" name="farmProduzione" :opzioni-farm="wizard.opzioniFarmProduzione" required />
    </div>

    <div v-if="wizard.sceltaFarmProduzione == 'altro'" class="question-wrapper">
      <wizard-question-farm v-model="wizard.sceltaFarmMondo" name="farmMondo" :opzioni-farm="wizard.opzioniFarmMondo" required />
    </div>

    <div v-if="wizard.askFarmBackup" class="question-wrapper">
      <h3>{{ $t('Server farm di Backup') }}</h3>
      <wizard-question-farm v-model="wizard.sceltaFarmBackup" name="farmBackup" :opzioni-farm="wizard.opzioniFarmBackup" is-backup required />
    </div>
  </wizard-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionFarm from '../questions/wizard-question-farm.vue';
import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepFarm',

  components: {
    WizardQuestionFarm,
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep({
        isEnabled: wizard => wizard.askFarm,
      }),
    };
  },
});
</script>
