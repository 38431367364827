<template>
  <div class="btn-group" :class="{ disabled }">
    <pf-button :variant="managedChecked ? 'success' : 'default'" x-small :disabled="disabled" @click="managedChecked = true">
      <input
        v-bind="$attrs"
        tabindex="-1"
        :name="name"
        :disabled="disabled"
        type="radio"
        :checked="managedChecked"
        @change="managedChecked = ($event.currentTarget as HTMLInputElement).checked"
      >
      {{ label }}
    </pf-button>

    <pf-button :variant="managedChecked ? 'default' : offVariant" x-small :disabled="disabled" @click="managedChecked = false">
      {{ labelOff }}
      <input
        v-if="name && isDefined(offValue) && !managedChecked"
        :name="name"
        type="hidden"
        :value="offValue"
        :disabled="disabled"
      >
    </pf-button>
  </div>
</template>

<style scoped>
label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 1px;
  height: 1px;
  margin: 0 -1px -1px 0;
  appearance: none;
  outline: none;
  z-index: -1;
  order: 100;
}
</style>

<script lang="ts" setup>
import { useManagedProp } from '@common/use';
import { isDefined } from '@vueuse/core';
import type { ButtonVariant } from 'node_modules/vue-patternfly/dist/types/components/Button.vue';
import type { HTMLAttributes, InputHTMLAttributes } from 'vue';

defineOptions({
  name: 'PfSwitch',
  inheritAttrs: false,
});

export interface Props extends /* @vue-ignore */ InputHTMLAttributes {
  checked?: boolean;

  /** Flag to reverse the layout of toggle and label (toggle on right). */
  reversed?: boolean;

  /** Text value for the label when on */
  label?: string;

  /** Text value for the label when off */
  labelOff?: string;

  labelAttrs?: HTMLAttributes;

  offVariant?: ButtonVariant;
  offValue?: string;
  name?: string;
  disabled?: boolean;
}

withDefaults(defineProps<Props>(), {
  label: 'ON',
  labelOff: 'OFF',
  checked: undefined,
  offVariant: 'danger',
});

defineEmits<{
  (name: 'update:checked', value: boolean): void;
}>();

defineSlots<Record<string, never>>();

const managedChecked = useManagedProp('checked', false);
</script>
