<template>
  <div v-if="expanded" class="list-group-item-container container-fluid">
    <slot />
  </div>
</template>

<style scoped>
.list-group-item-container {
  cursor: initial;
}
</style>

<script lang="ts" setup>
import { inject, type HTMLAttributes } from 'vue';
import { DataListItemExpandedKey } from './PfDataListItem.vue';

export interface Props extends /* @vue-ignore */ HTMLAttributes {}

defineProps<Props>();

defineSlots<{
  default?: (props?: Record<never, never>) => any;
}>();

const expanded = inject(DataListItemExpandedKey);
</script>
