<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Intestazione')">
    <div class="question-wrapper">
      <h3>{{ $t('A chi vuoi intestare il preventivo?') }}</h3>
      <toggle
        v-model="wizard.fatturaMe"
        :on-value="true"
        :off-value="false"
        :on-text="$t('Direttamente me')"
        :off-text="$t('Ad un mio cliente')"
        off-class="btn-success"
      />
    </div>

    <div v-if="!wizard.fatturaMe" class="question-wrapper">
      <h3>{{ $t('A quale cliente vuoi intestare il preventivo?') }}</h3>
      <div v-if="updating" class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Stiamo caricando la tua lista clienti...') }}
        </h3>
        <p class="blank-slate-pf-secondary-action">
          {{ $t('Ancora pochi istanti e potrai continuare il tuo preventivo.') }}
        </p>
      </div>
      <div v-for="c of wizard.customers" v-show="!updating && wizard.customers.length > 0" :key="c.id">
        <label>
          <input
            type="radio"
            name="idClienteFinale"
            :value="c.id"
            required
            :checked="wizard.customer?.id === c.id"
            @change="wizard.idClienteFinale = Number(($event.target as HTMLInputElement).value)"
          >
          {{ c.ragione_sociale }}
        </label>
      </div>
      <div v-if="!updating && !wizard.customers.length">
        {{ $t('Nessun cliente trovato, utilizza il form qui sotto per inserire un nuovo prospect') }}
        <input type="text" required style="display: none">
      </div>
    </div>

    <div v-if="!wizard.fatturaMe" class="question-wrapper">
      <pf-button type="button" class="btn btn-default" @click="openFromProspect = true"> {{ $t("Oppure crea un'azienda prospect") }}</pf-button>
      <pf-modal
        v-if="openFromProspect"
        show
        :title="$t('Inserisci un nuovo prospect')"
        confirm-button=""
        cancel-button=""
        @cancel="openFromProspect = false"
      >
        <form-prospect :key="formKey" v-model:id-prospect="wizard.idClienteFinale" modal @submit="refreshCustomers" />
      </pf-modal>
    </div>
  </wizard-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import Toggle from '../../toggle.vue';
import FormProspect from '../../form-prospect.vue';
import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepClienti',

  components: {
    Toggle,
    FormProspect,
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep(),
    };
  },

  data() {
    return {
      openFromProspect: false,
      formKey: 0, // utilizzata per effettuare il reset del form
    };
  },

  computed: {
    updating() {
      return this.wizard.asyncComputed.customers.updating;
    },
  },

  methods: {
    refreshCustomers() {
      this.formKey += 1;
      this.wizard.asyncComputed.customers.update();
      this.openFromProspect = false;
    },
  },
});
</script>
