import { http } from "@/http";
import { pop } from '@common/utils';
import { ref, type Ref } from "vue";
import { useRoute } from "vue-router";

export function useOauthUrls() {
  const route = useRoute();
  const linkedInLoginUrl: Ref<string | null> = ref(null);

  (async function() {
    const url = new URL('/api/auth/oauth-urls', window.location.href);
    const antk = pop(route?.query.antk);
    if (antk) {
      url.searchParams.append('antk', antk);
    }

    const response = await http.get(url.toString());
    if (response.data['linkedin-login-url']) {
      linkedInLoginUrl.value = response.data['linkedin-login-url'];
    }
  })();

  return {
    linkedInLoginUrl,
  };
}
