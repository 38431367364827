<template>
  <li v-if="totali[durata].scontato > 0" class="row">
    <template v-if="hasSconto">
      <span class="col-xs-12 col-sm-4 striked">{{ $t('Totale {0}', [$t(durata)]) }}:</span>
      <span class="col-xs-12 col-sm-2 striked value-cell"> {{ $currency(totali[durata].listino, currency) }}</span>
    </template>
    <span class="col-xs-12 col-sm-4">{{ $t('Totale {0} a te riservato', [$t(durata)]) }}:</span>
    <span class="col-xs-12 col-sm-2 value-cell ">{{ $currency(totali[durata].scontato, currency) }}</span>
  </li>
</template>

<style scoped>
.striked {
  text-decoration: line-through;
}

.value-cell{
  text-align: right;
}
</style>

<script lang="ts">
import type { OfferTotals } from "@/resources";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: 'OffersTotal',

  props: {
    totali: {
      type: Object as PropType<OfferTotals>,
      required: true,
    },
    durata: {
      type: String as PropType<keyof OfferTotals>,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasSconto() {
      return this.totali[this.durata].listino != this.totali[this.durata].scontato;
    },
  },
});
</script>
