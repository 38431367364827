import { inject, computed, getCurrentInstance, type ComponentPublicInstance } from 'vue';
import { useChildrenTracker, type ChildrenTrackerInjectionKey } from '@common/use';
import { WizardInjectionKey, type WizardInjection } from './wizard.vue';

type StepData = ReturnType<typeof useWizardStep>;
type StepComponent = ComponentPublicInstance<object, object, StepData>;

export const WizardStepsTrackerSymbol: ChildrenTrackerInjectionKey<StepComponent> = Symbol('Wizard steps tracker symbol');

export function useWizard() {
  const wizard = inject(WizardInjectionKey);
  if (!wizard) {
    throw new Error('WizardStep component must be used inside Wizard component');
  }
  return wizard;
}

export function useWizardStep({
  isEnabled = (() => true) as (wizard: WizardInjection) => boolean,
} = {}) {
  const instance = getCurrentInstance()?.proxy;
  useChildrenTracker(WizardStepsTrackerSymbol);
  const wizard = useWizard();

  return {
    wizard,
    hidden: computed(() => wizard.currentStep !== instance?.$options.name),
    isEnabled: computed(() => wizard && isEnabled(wizard)),
  };
}

export function useWizardQuestion() {
  return {
    wizard: useWizard(),
  };
}
